@charset "UTF-8";
:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

app-stream-details {
  display: flex;
  flex-direction: column;
  height: 100%;
}
app-stream-details .container {
  flex: 1 0 auto;
}
@media only screen and (max-width : 772px) {
  app-stream-details .content-wrapper {
    overflow: hidden;
  }
}
app-stream-details .stream-wrapper {
  flex-direction: column;
}
app-stream-details .stream-wrapper.wrapper--centered {
  width: 960px;
}
@media only screen and (max-width : 1024px) {
  app-stream-details .stream-wrapper.wrapper--centered {
    width: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  app-stream-details .stream-wrapper.wrapper--centered {
    width: auto;
    margin: 75px 25px 10px !important;
  }
  app-stream-details .stream-wrapper.wrapper--centered .stream-notes-wrapper {
    margin: 10px 0;
  }
}
app-stream-details .stream-wrapper .stream-details {
  position: relative;
  display: flex;
  flex: 0 1;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  margin: 0 0 50px;
  font-family: var(--font-family-georgia);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -khtml-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}
app-stream-details .stream-wrapper .stream-details__stream-options {
  display: flex;
  align-items: center;
}
@media only screen and (max-width : 736px) {
  app-stream-details .stream-wrapper .stream-details__stream-options {
    flex-direction: column;
  }
}
app-stream-details .stream-wrapper .stream-details__stream-options__author-info {
  display: flex;
  align-items: center;
  font-size: 1em;
  margin: 0 auto 0 0;
}
@media only screen and (max-width : 736px) {
  app-stream-details .stream-wrapper .stream-details__stream-options__author-info {
    margin: 20px 0;
  }
}
app-stream-details .stream-wrapper .stream-details__stream-options__author-info__avatar {
  width: 40px;
  height: 40px;
  margin: 0 10px 0 0;
  cursor: pointer;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
app-stream-details .stream-wrapper .stream-details__stream-options__author-info__avatar:hover {
  opacity: 0.7;
}
app-stream-details .stream-wrapper .stream-details__stream-options__author-info__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: var(--font-family-cantarell);
  text-transform: uppercase;
  font-size: 0.7em;
}
app-stream-details .stream-wrapper .stream-details__stream-options__author-info__details__label {
  color: #8e9198;
  margin: 0 0 4px;
}
app-stream-details .stream-wrapper .stream-details__stream-options__author-info__details__name {
  text-decoration: none;
  color: #212529;
  font-weight: 600;
}
app-stream-details .stream-wrapper .stream-details__stream-options__author-info__details__name:hover {
  color: black;
}
app-stream-details .stream-wrapper .stream-details__stream-options__actions {
  display: flex;
  align-items: center;
  font-size: 1.6em;
}
app-stream-details .stream-wrapper .stream-details__stream-options__actions button {
  margin: 10px 5px 0 !important;
}
@media only screen and (max-width : 736px) {
  app-stream-details .stream-wrapper .stream-details__stream-options__actions {
    justify-content: center;
    flex-wrap: wrap;
  }
}
app-stream-details .stream-wrapper .stream-details__stream-options__actions .subscribe--button {
  padding: 8px 10px;
  margin: 0 10px 0 20px;
  font-family: var(--font-family-cantarell);
  text-transform: uppercase;
  font-size: 0.5em;
  border: 0 none;
  background-color: #c00;
  color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -khtml-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}
app-stream-details .stream-wrapper .stream-details__stream-options__actions .subscribe--button:hover {
  background-color: #b30606;
}
app-stream-details .stream-wrapper .stream-details__stream-options__actions .button--icon {
  position: relative;
  padding-left: 35px;
}
app-stream-details .stream-wrapper .stream-details__stream-options__actions .button--icon svg {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
app-stream-details .stream-wrapper .stream-details__stream-options__actions .button--icon svg path {
  fill: #fff;
  stroke-width: 1;
  stroke: none;
}
app-stream-details .stream-wrapper .stream-details__thumb-desc:after {
  content: "";
  clear: both;
  display: table;
}
app-stream-details .stream-wrapper .stream-details__thumbnail {
  height: 400px;
  width: 400px;
  min-width: 400px;
  display: flex;
  float: left;
  margin: 0 20px 20px 0;
}
@media only screen and (max-width : 736px) {
  app-stream-details .stream-wrapper .stream-details__thumbnail {
    display: block;
    height: 90vw;
    width: 100%;
    min-width: auto;
    float: none;
  }
}
app-stream-details .stream-wrapper .stream-details__title {
  margin: 20px 0 10px;
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--font-family-georgia);
}
@media only screen and (max-width : 736px) {
  app-stream-details .stream-wrapper .stream-details__title {
    flex-direction: column;
  }
}
app-stream-details .stream-wrapper .stream-details__title__stats-holder {
  display: flex;
  flex-direction: column;
  margin: 0 10px 0 0;
}
@media only screen and (max-width : 736px) {
  app-stream-details .stream-wrapper .stream-details__title__stats-holder {
    margin: 10px 0;
  }
}
app-stream-details .stream-wrapper .stream-details__title__stats-holder__label {
  font-size: 1.25em;
  font-weight: 500;
  margin: 0;
}
app-stream-details .stream-wrapper .stream-details__title__stats-holder__subscribers {
  color: #8e9198;
  font-size: 0.5em;
}
@media only screen and (max-width : 736px) {
  app-stream-details .stream-wrapper .stream-details__title__stats-holder__subscribers {
    text-align: center;
  }
}
app-stream-details .stream-wrapper .stream-details__title__actions {
  display: flex;
  align-items: center;
  font-size: 0.7em;
}
@media only screen and (max-width : 736px) {
  app-stream-details .stream-wrapper .stream-details__title__actions {
    margin: 10px auto;
  }
}
app-stream-details .stream-wrapper .stream-details__title__actions__follow-wrapper {
  display: flex;
  align-items: baseline;
}
app-stream-details .stream-wrapper .stream-details__title__actions__follow-wrapper__bell-icon {
  position: relative;
  background: none !important;
  padding: 0 !important;
}
app-stream-details .stream-wrapper .stream-details__title__actions__follow-wrapper__bell-icon__bell {
  height: 26px;
  width: 26px;
}
app-stream-details .stream-wrapper .stream-details__title__actions__follow-wrapper__bell-icon__checked {
  position: absolute;
  width: 18px;
  border-radius: 16px;
  background: #f7f7f7;
  height: 18px;
  left: 13px;
  top: 2px;
}
app-stream-details .stream-wrapper .stream-details__title__actions__follow-wrapper__bell-icon:hover svg {
  fill: #8e9198;
}
app-stream-details .stream-wrapper .stream-details__title__actions__follow-wrapper label {
  ffont-family: var(--font-family-cantarell);
  text-transform: uppercase;
  font-size: 0.7em;
  margin: 0;
}
app-stream-details .stream-wrapper .stream-details__title__actions__follow-wrapper button {
  margin: 0 7px;
  padding: 0 8px;
  height: 36px;
  background: #39aea9;
  color: white;
  border: 0 none;
  font-family: var(--font-family-cantarell);
  text-transform: uppercase;
  font-size: 0.7em;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -khtml-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
}
app-stream-details .stream-wrapper .stream-details__title__actions__follow-wrapper button:hover {
  background-color: #339e9a;
}
app-stream-details .stream-wrapper .stream-details__title__actions__follow-wrapper button.button--followed:after {
  content: "Following";
}
app-stream-details .stream-wrapper .stream-details__title__actions__follow-wrapper button.button--followed:hover {
  background-color: black;
}
app-stream-details .stream-wrapper .stream-details__title__actions__follow-wrapper button.button--followed:hover:after {
  content: "Unfollow";
}
app-stream-details .stream-wrapper .stream-details__title__actions .share--button {
  margin: 0 0 0 10px;
  padding: 0 8px;
  height: 36px;
  font-family: var(--font-family-cantarell);
  text-transform: uppercase;
  font-size: 0.7em;
  background-color: #d2dfff;
  border: 0 none;
  color: #4e7ef8;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -khtml-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
}
app-stream-details .stream-wrapper .stream-details__title__actions .share--button:hover {
  background-color: #becff9;
}
app-stream-details .stream-wrapper .stream-details__title__actions .share--button svg {
  height: 18px;
  width: 18px;
  margin: 0 3px 0 0;
  fill: #4e7ef8;
}
app-stream-details .stream-wrapper .stream-details__title__actions .share--button social-sharing {
  display: none;
}
app-stream-details .stream-wrapper .stream-details__title__actions .label-info {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 10px;
  padding: 0 8px;
  height: 36px;
  font-family: var(--font-family-cantarell);
  text-transform: uppercase;
  font-size: 0.7em;
  background-color: #fff;
  border: 0 none;
  color: #4e7ef8;
  cursor: pointer;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -khtml-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
}
app-stream-details .stream-wrapper .stream-details__title__actions .label-info svg {
  margin: 0 6px 0 0;
}
app-stream-details .stream-wrapper .stream-details__title__actions .label-info.type--alert {
  border: 1px solid red;
  color: red;
}
app-stream-details .stream-wrapper .stream-details__title__actions .label-info.type--alert svg {
  fill: red;
}
@media only screen and (max-width : 736px) {
  app-stream-details .stream-wrapper .stream-details__title__actions .tooltip {
    display: none;
  }
}
app-stream-details .stream-wrapper .stream-details__description {
  color: #757575;
  line-height: 1.6em;
  margin: 0 0 20px;
}
app-stream-details .stream-wrapper .stream-details__other {
  text-align: center;
}
app-stream-details .stream-wrapper .stream-details__other__loader {
  font-size: 0.9em;
  margin: 10px auto;
  text-align: center;
}
app-stream-details .stream-wrapper .stream-details__other .login-or-subscribe-info {
  border-top: 1px solid rgba(211, 211, 211, 0.4784313725);
  padding: 10px 0 0;
  margin: 20px 0 0;
}
app-stream-details .stream-wrapper .stream-details__other .login-or-subscribe-info .signup-or-login-button {
  color: #fff;
  font-family: var(--font-family-cantarell);
  text-transform: uppercase;
  font-size: 0.7em;
  border: 0 none;
  padding: 3px;
  margin: 0 auto;
  background-color: #c00;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -khtml-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}
app-stream-details .stream-wrapper .stream-details__other .login-or-subscribe-info .signup-or-login-button:hover {
  background-color: #339e9a;
}
app-stream-details .stream-wrapper .stream-details__other .login-or-subscribe-info .signup-or-login-button.min-price-label.button-spinner {
  padding-left: 30px;
}
app-stream-details .stream-wrapper .stream-details__other .login-or-subscribe-info .signup-or-login-button.min-price-label.button-spinner:after {
  left: 5px;
  top: 9px;
  right: initial;
}
app-stream-details .stream-wrapper .stream-details__other .login-or-subscribe-info .signup-or-login-button .subs-min-price {
  bottom: 2px;
  background: white;
  font-weight: bold;
  line-height: 2.4em;
  padding: 0 5px;
  margin: 0 0 0 10px;
  color: #000;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -khtml-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
}
@media only screen and (max-width : 736px) {
  app-stream-details .stream-wrapper .stream-details__other .login-or-subscribe-info .signup-or-login-button .subs-min-price {
    top: 32px;
    left: 2px;
    font-size: 0.9em;
  }
}
app-stream-details .stream-wrapper .stream-details__other .exp-subscription-messages {
  padding: 10px 0 0;
  margin: 10px 0 0;
  font-family: var(--font-family-cantarell);
  text-transform: uppercase;
  font-size: 0.9em;
  text-align: center;
  border-top: 1px solid rgba(211, 211, 211, 0.4784313725);
}
app-stream-details .stream-wrapper .stream-details__other .exp-subscription-messages .message {
  cursor: pointer;
  color: #2f8e8a;
  font-weight: 600;
}
app-stream-details .stream-wrapper .stream-details__other .exp-subscription-messages .message:hover {
  color: #212529;
}
app-stream-details .stream-wrapper .stream-details__other .close-sub-button {
  font-size: 0.8em;
  margin: 0 auto;
  display: flex;
  min-width: 400px;
  justify-content: center;
}
app-stream-details .stream-wrapper .stream-details .free-users-info {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #39aea9;
  line-height: 1.25em;
  padding: 10px 30px;
  bottom: -50px;
  width: 180px;
  font-weight: 600;
  text-align: center;
  right: -73px;
  transform: rotate(-45deg);
  color: white;
  height: 130px;
}
app-stream-details .stream-wrapper .stream-details .free-users-info .sub-price {
  font-family: var(--font-family-cantarell);
  text-transform: uppercase;
  font-size: 1em;
  display: none;
  font-weight: 600;
  padding: 0 0 4px;
}
app-stream-details .stream-wrapper .stream-details .free-users-info.extend-price {
  flex-direction: column;
  padding: 10px 50px;
  bottom: -37px;
  width: 230px;
  font-weight: 400;
  right: -87px;
  font-size: 0.9em;
}
app-stream-details .stream-wrapper .stream-details .free-users-info.extend-price .sub-price {
  font-size: 1.1em;
  display: flex;
  justify-content: center;
}
app-stream-details .stream-wrapper .stream-details__thumb-desc {
  display: flex;
}
@media only screen and (max-width : 736px) {
  app-stream-details .stream-wrapper .stream-details__thumb-desc {
    flex-direction: column;
  }
}
app-stream-details .stream-wrapper .stream-details__add-thumb-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 10px 10px 0;
  padding: 10px;
  width: 250px;
  min-width: 250px;
  background-color: rgba(211, 211, 211, 0.2588235294);
  cursor: pointer;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -khtml-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}
app-stream-details .stream-wrapper .stream-details__add-thumb-wrapper:hover {
  background-color: rgba(211, 211, 211, 0.4784313725);
}
@media only screen and (max-width : 736px) {
  app-stream-details .stream-wrapper .stream-details__add-thumb-wrapper {
    width: 100%;
    margin: 0 0 20px;
  }
}
app-stream-details .stream-wrapper .stream-details__add-thumb-wrapper svg {
  width: 140px;
  margin: 10px 0;
}
app-stream-details .stream-wrapper .stream-details__add-thumb-wrapper svg path {
  fill: #38aea9;
}
app-stream-details .stream-wrapper .stream-details__add-thumb-wrapper__title {
  text-align: center;
  margin: 0 0 10px;
}
app-stream-details .stream-wrapper .subscriptions-wrapper {
  display: none;
  flex: 1;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -khtml-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}
app-stream-details .stream-wrapper .subscriptions-wrapper.plans--visible {
  display: flex;
}
app-stream-details .stream-wrapper .stream-notes-wrapper {
  margin: 30px 0;
}
@media only screen and (max-width : 1024px) {
  app-stream-details .stream-wrapper .stream-notes-wrapper {
    margin: 10px 60px;
  }
}
@media only screen and (max-width : 772px) {
  app-stream-details .stream-wrapper .stream-notes-wrapper {
    margin: 10px 20px;
  }
}
@media only screen and (max-width : 736px) {
  app-stream-details .stream-wrapper .stream-notes-wrapper {
    margin: 10px 0;
  }
}
app-stream-details .stream-wrapper .stream-notes-wrapper__loading-notes {
  display: flex;
  background-color: white;
  font-family: var(--font-family-georgia);
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -khtml-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}
app-stream-details .stream-wrapper .stream-notes-wrapper__loading-notes fa-icon {
  margin: 0 10px 0 0;
}
@media only screen and (max-width : 736px) {
  app-stream-details .stream-wrapper .stream-notes-wrapper note .note-wrapper__content__current__text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}
app-stream-details .stream-wrapper__show-more {
  padding: 5px 0 20px;
  text-align: center;
}
app-stream-details .stream-wrapper__show-more button {
  font-size: 0.9em;
}
app-stream-details h3.subheader {
  height: 24px;
  font-family: var(--font-family-titillium);
  font-size: 24px;
  color: #5b5151;
  font-weight: 700;
  text-decoration: none solid rgb(91, 81, 81);
  line-height: 24px;
}
app-stream-details .expiration-message, app-stream-details .extend-subscription {
  cursor: pointer;
  font-weight: bold;
  padding: 6px 2px;
}
app-stream-details .expiration-message:hover, app-stream-details .extend-subscription:hover {
  text-decoration: underline;
}
app-stream-details .expiration-message span, app-stream-details .extend-subscription span {
  margin-right: 2px;
}
app-stream-details .expiration-message {
  color: #ad3a3a;
}
app-stream-details .extend-subscription {
  color: #557b83;
}
app-stream-details .stream-edition-controls {
  display: flex;
  flex-flow: column;
}
app-stream-details .stream-edition-controls button {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  min-height: 35px;
  line-height: 35px;
}
app-stream-details .stream-edition-controls button.share-stream-button {
  margin-top: 5px !important;
}
app-stream-details p {
  word-break: break-word;
}
app-stream-details ngb-rating {
  outline: none;
}
app-stream-details .no-mobile {
  display: block;
}
@media only screen and (max-width : 736px) {
  app-stream-details .no-mobile {
    display: none;
  }
}
app-stream-details .no-desktop {
  display: none;
}
@media only screen and (max-width : 736px) {
  app-stream-details .no-desktop {
    display: block;
  }
}
app-stream-details .stream-action-button {
  display: none;
}
app-stream-details .stream-action-button button {
  margin: 5px 7px 5px 0 !important;
}
@media only screen and (max-width : 772px) {
  app-stream-details .stream-action-button button, app-stream-details .stream-action-button stream-wizard {
    margin: 2px 0 !important;
    flex: 0 49%;
  }
  app-stream-details .stream-action-button stream-wizard button {
    margin: 0 !important;
    width: 100%;
  }
}
@media only screen and (max-width : 772px) {
  app-stream-details .stream-action-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
app-stream-details .subscriptions-dropdown {
  margin-bottom: 20px;
}
app-stream-details .subscriptions-loader {
  border-top: 1px solid rgba(211, 211, 211, 0.4784313725);
  font-size: 0.9em;
  margin: 10px 0 0;
  padding: 20px 0 0;
  width: 100%;
}
app-stream-details .subscriptions-loader .ng-fa-icon {
  margin: 5px;
}
app-stream-details .search-notes-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 15px;
}
app-stream-details .search-notes-wrapper search-input {
  flex: 0 400px;
}
app-stream-details .search-notes-wrapper search-input .notes-search input {
  border: 1px solid rgba(211, 211, 211, 0.568627451) !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -khtml-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  border-radius: 5px !important;
}

.stream-details .stream-info {
  flex-direction: column;
}
.stream-details .notd-thumb {
  flex: 0 0 auto;
  margin: 0 10px 10px 0;
}
.stream-details .notd-thumb img {
  max-width: 100%;
  width: 110px;
}
.stream-details .notd-thumb > div > button {
  margin-top: 0;
}
.stream-details .notd-thumb > div > button:first-child {
  margin-top: 10px;
}
.stream-details .notd-thumb button {
  width: 100%;
  word-break: break-word;
  min-width: 0;
}
@media only screen and (min-width: 767px) {
  .stream-details .notd-thumb button {
    max-width: 110px;
  }
}
@media only screen and (max-width : 736px) {
  .stream-details .notd-thumb {
    width: 110px;
  }
}
.stream-details .notd-details {
  flex: 1 1 auto;
  background: white;
  padding: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  -moz-box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
}
.stream-details .notd-details .edit-btn {
  display: none;
  margin: 5px 0 0;
}
@media only screen and (max-width : 736px) {
  .stream-details .notd-details .edit-btn {
    display: block;
  }
}
@media only screen and (max-width : 736px) {
  .stream-details .notd-details .desc {
    display: none;
  }
}
.stream-details .notd-details .rate {
  color: #ffd900;
  font-size: 18px;
  padding: 0 30px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (max-width : 736px) {
  .stream-details .notd-details .rate {
    margin-left: 15px;
    flex: 1 1 auto;
    justify-content: flex-start;
  }
}
.stream-details .notd-details .rate .unrated {
  color: #cccccc;
}
.stream-details .notd-details .rate .fa-star:before {
  padding-right: 2px;
}
.stream-details .notd-details .action-buttons {
  margin-bottom: 20px;
}
.stream-details .notd-details .action-buttons button {
  margin: 3px;
  height: auto;
  font-size: 0.8em;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.stream-details .notd-details .action-buttons button:hover {
  background-color: #ad3a3a;
}
@media only screen and (max-width : 736px) {
  .stream-details .notd-details .action-buttons {
    display: flex;
    flex-flow: column;
  }
  .stream-details .notd-details .action-buttons button {
    margin: 3px;
  }
}
.stream-details .notd-details .buttons button {
  width: 120px;
}
.stream-details .notd-details .notd-thumb {
  display: none;
}
@media only screen and (max-width : 736px) {
  .stream-details .notd-details .notd-thumb {
    display: block;
    margin: 0 24px 10px 40px;
  }
}
.stream-details .notd-details.free-users {
  overflow: hidden;
  position: relative;
}
.stream-details .notd-details.free-users .free-users-info {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #39aea9;
  line-height: 1.25em;
  padding: 10px 30px;
  bottom: -50px;
  width: 180px;
  font-weight: 600;
  text-align: center;
  right: -73px;
  transform: rotate(-45deg);
  color: white;
  height: 130px;
}
.stream-details .notd-details.free-users .free-users-info .sub-price {
  display: none;
  font-weight: 600;
  font-size: 1.15em;
  padding: 0 0 4px;
}
.stream-details .notd-details.free-users .free-users-info.extend-price {
  flex-direction: column;
  padding: 10px 50px;
  bottom: -33px;
  width: 230px;
  font-weight: 400;
  right: -83px;
}
.stream-details .notd-details.free-users .free-users-info.extend-price .sub-price {
  display: flex;
  justify-content: center;
}
.stream-details .notd-details.free-users .login-or-subscribe .signup-or-login-button {
  padding-right: 15px;
}
.stream-details .notd-details.free-users .login-or-subscribe .subs-min-price {
  display: none;
}
.stream-details .notd-details .free-users-info {
  display: none;
}
.stream-details .share-stream-button {
  padding: 0;
  height: 35px;
  display: inline-flex;
  align-items: baseline;
  justify-content: center;
  background-color: #39aea9;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.stream-details .share-stream-button:before {
  content: "Share";
  margin-right: 10px;
}
.stream-details .share-stream-button:hover {
  background-color: #557b83;
}
.stream-details .share-stream-button social-sharing svg {
  width: 23px !important;
  height: 23px !important;
}
.stream-details .share-stream-button social-sharing svg path {
  fill: #fff !important;
}
.stream-details .stats {
  width: 100%;
}
.stream-details .stats div {
  font-size: 16px;
}
.stream-details stream-plans {
  width: 100%;
}
.stream-details .stream-earnings {
  margin-bottom: 20px;
}
.stream-details .note-loading-wrapper {
  font-size: 0.9em;
  padding: 50px 0;
  background: #fff;
  margin: 20px 0;
  -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  -moz-box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
}
.stream-details .note-loading-wrapper .ng-fa-icon {
  margin: 5px;
}

.notd-stream-price-list {
  margin-top: 20px;
}
.notd-stream-price-list .notd-stream-price-list-header {
  padding-top: 20px;
}
.notd-stream-price-list .notd-stream-price-list-header > div {
  display: inline-block;
}

.notd-stream-subscribers {
  margin-bottom: 20px;
}
.notd-stream-subscribers .stream-owner {
  font-weight: bold;
  margin: 0 15px;
  flex: 1 0 100%;
}
.notd-stream-subscribers .stream-owner .profile-name {
  color: #557b83;
  display: inline-block;
  margin: 0 0 0 15px;
}
@media only screen and (max-width : 736px) {
  .notd-stream-subscribers .stream-owner .profile-name {
    margin: 0;
  }
}
.notd-stream-subscribers .stats-container {
  align-items: baseline;
  font-weight: bold;
  margin: 0 15px 0;
  flex: 1 0 100%;
}
.notd-stream-subscribers .stats-container a {
  color: #557b83;
  font-weight: bold;
}
.notd-stream-subscribers .stats-container .sub-counter {
  font-weight: normal;
  display: inline-block;
  padding: 0 15px;
}
.notd-stream-subscribers .stats-label {
  font-weight: bold;
  max-width: 160px;
}
@media only screen and (max-width : 736px) {
  .notd-stream-subscribers .stats-value {
    text-align: center;
  }
}
@media only screen and (min-width: 768px) {
  .notd-stream-subscribers .info {
    display: flex;
  }
}
.notd-stream-subscribers .info > div {
  flex-direction: column;
  display: flex;
}
.notd-stream-subscribers .info > div > div {
  flex: 1 1 auto;
  font-size: 20px;
  margin-bottom: 5px;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .notd-stream-subscribers .info > div > div {
    font-size: 16px;
  }
}
.notd-stream-subscribers .info > div > div > div:first-child {
  font-weight: bold;
}
@media only screen and (max-width: 767px) {
  .notd-stream-subscribers .info > div > div > div:last-child {
    text-align: right;
  }
}

stream-info-form {
  display: block;
  overflow: hidden;
}

.stream-info .info-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stream-info.editing-old {
  box-shadow: 1px 1px 7px rgba(130, 130, 130, 0.5);
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  background: rgb(231, 234, 237);
}
.stream-info button {
  margin: 20px 0 5px 0;
}
.stream-info .notd-stream-info-header {
  padding-bottom: 10px;
  color: #ad3a3a;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  margin: 0;
}
@media only screen and (max-width : 736px) {
  .stream-info .notd-stream-info-header {
    flex: 1 0;
    padding: 0 15px 0 0;
  }
}
.stream-info .btn-close span {
  padding-left: 10px;
}
.stream-info .separate-description {
  font-size: 14px;
  display: none;
  padding: 0 0 10px;
}
@media only screen and (max-width : 736px) {
  .stream-info .separate-description {
    display: block;
  }
}
.stream-info .notd-details button {
  margin: 20px 5px;
}

.stream-copyright {
  color: #5b5151;
  font-size: 14px;
  text-align: center;
  padding: 20px 0 0;
}

::ng-deep ngb-modal-backdrop {
  background-color: #fff !important;
}

::ng-deep .following-modal {
  font-family: var(--font-family-georgia);
}
::ng-deep .following-modal .modal-dialog .modal-content {
  position: relative;
  padding: 20px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px 0px !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px 0px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px 0px !important;
}
@media only screen and (max-width : 736px) {
  ::ng-deep .following-modal .modal-dialog .modal-content {
    padding: 10px;
  }
}
::ng-deep .following-modal__close-btn {
  display: inline-flex;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
::ng-deep .following-modal__close-btn svg {
  height: 26px;
  width: 26px;
}
::ng-deep .following-modal__close-btn svg:hover {
  opacity: 0.6;
}
::ng-deep .following-modal__title {
  font-size: 1.4em;
  text-align: center;
  margin: 0;
  padding: 0;
}
@media only screen and (max-width : 736px) {
  ::ng-deep .following-modal__title {
    padding: 0 45px 0 0;
  }
}
::ng-deep .following-modal__content {
  padding: 25px 5px;
}
::ng-deep .following-modal__content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
::ng-deep .following-modal__content ul li {
  display: flex;
  align-items: center;
  margin: 12px 0;
  cursor: pointer;
}
::ng-deep .following-modal__content ul li input {
  margin: 0 10px 0 0;
}
::ng-deep .following-modal__content ul li span {
  flex: 1;
}
::ng-deep .following-modal__footer {
  padding: 5px 0;
  text-align: center;
}
::ng-deep .following-modal__footer button {
  font-size: 0.9em;
}

::ng-deep .blocked-users-modal {
  font-family: var(--font-family-georgia);
}
::ng-deep .blocked-users-modal .modal-dialog .modal-content {
  position: relative;
  padding: 20px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px 0px !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px 0px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px 0px !important;
}
@media only screen and (max-width : 736px) {
  ::ng-deep .blocked-users-modal .modal-dialog .modal-content {
    padding: 10px;
  }
}
::ng-deep .blocked-users-modal__close-btn {
  display: inline-flex;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
::ng-deep .blocked-users-modal__close-btn svg {
  height: 26px;
  width: 26px;
}
::ng-deep .blocked-users-modal__close-btn svg:hover {
  opacity: 0.6;
}
::ng-deep .blocked-users-modal__title {
  font-size: 1.4em;
  text-align: center;
  margin: 0;
  padding: 0;
}
@media only screen and (max-width : 736px) {
  ::ng-deep .blocked-users-modal__title {
    padding: 0 45px 0 0;
  }
}
::ng-deep .blocked-users-modal__content__text {
  margin: 20px 0 10px;
}
::ng-deep .blocked-users-modal__content__info {
  margin: 10px 0;
  display: flex;
  align-items: center;
}
@media only screen and (max-width : 736px) {
  ::ng-deep .blocked-users-modal__content__info {
    margin: 10px 5px;
  }
}
::ng-deep .blocked-users-modal__content__info svg {
  width: 26px;
  height: 26px;
  margin: 0 5px 0 0;
}
::ng-deep .blocked-users-modal__content__info p {
  flex: 1;
  font-size: 0.8em;
  color: grey;
  margin: 0;
}
::ng-deep .blocked-users-modal__content__buttons {
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}
::ng-deep .blocked-users-modal__content__buttons button {
  margin: 0 2px;
  font-size: 0.8em;
}
::ng-deep .blocked-users-modal__content__buttons button:disabled {
  border: none;
}
::ng-deep .blocked-users-modal__content__list {
  list-style: none;
  margin: 20px 0 30px;
  padding: 0;
}
::ng-deep .blocked-users-modal__content__list__counter {
  margin: 0 5px 0 0;
}
::ng-deep .blocked-users-modal__content__list__user {
  flex: 1;
  text-decoration: none;
  color: #39aea9;
}
::ng-deep .blocked-users-modal__content__list__user:hover {
  color: black;
  text-decoration: none;
}
::ng-deep .blocked-users-modal__content__list__actions {
  margin: 0 0 0 10px;
}
::ng-deep .blocked-users-modal__content__list__actions button {
  font-size: 0.7em;
}
::ng-deep .blocked-users-modal__content__list li {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
::ng-deep .blocked-users-modal__content__list li:last-child {
  border-bottom: 0;
}
::ng-deep .blocked-users-modal__content__message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px 10px 10px;
}
::ng-deep .blocked-users-modal__content__message svg {
  width: 250px;
  height: 250px;
}
::ng-deep .blocked-users-modal__content__message p {
  padding: 20px 0;
}

::ng-deep .stream-members-modal {
  font-family: var(--font-family-georgia);
}
::ng-deep .stream-members-modal .modal-dialog .modal-content {
  position: relative;
  padding: 20px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px 0px !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px 0px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px 0px !important;
}
@media only screen and (max-width : 736px) {
  ::ng-deep .stream-members-modal .modal-dialog .modal-content {
    padding: 10px;
  }
}
::ng-deep .stream-members-modal__close-btn {
  display: inline-flex;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
::ng-deep .stream-members-modal__close-btn svg {
  height: 26px;
  width: 26px;
}
::ng-deep .stream-members-modal__close-btn svg:hover {
  opacity: 0.6;
}
::ng-deep .stream-members-modal__title {
  font-size: 1.4em;
  text-align: center;
  margin: 0;
  padding: 0;
}
@media only screen and (max-width : 736px) {
  ::ng-deep .stream-members-modal__title {
    padding: 0 45px 0 0;
  }
}
::ng-deep .stream-members-modal__content__text {
  margin: 20px 0 10px;
}
::ng-deep .stream-members-modal__content__invite {
  margin: 15px 20px 5px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2901960784);
}
::ng-deep .stream-members-modal__content__invite alert-message .alert-wrapper {
  border: 1px solid rgba(128, 128, 128, 0.0901960784);
}
::ng-deep .stream-members-modal__content__invite__form {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width : 736px) {
  ::ng-deep .stream-members-modal__content__invite__form {
    flex-direction: column;
  }
}
::ng-deep .stream-members-modal__content__invite__form.form--invalid {
  padding: 0 0 15px;
}
::ng-deep .stream-members-modal__content__invite__form label {
  margin: 0;
  padding: 0;
}
@media only screen and (max-width : 736px) {
  ::ng-deep .stream-members-modal__content__invite__form label {
    margin: 0 0 10px;
  }
}
::ng-deep .stream-members-modal__content__invite__form__input-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}
::ng-deep .stream-members-modal__content__invite__form__input-wrapper input {
  margin: 0 15px;
  font-family: var(--font-family-georgia);
}
::ng-deep .stream-members-modal__content__invite__form__input-wrapper__error {
  font-size: 0.85em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
}
@media only screen and (max-width : 736px) {
  ::ng-deep .stream-members-modal__content__invite__form__input-wrapper__error {
    position: relative;
    bottom: inherit;
    left: inherit;
    right: inherit;
    margin: 10px 0 0;
  }
}
::ng-deep .stream-members-modal__content__invite__form__input-wrapper__error svg {
  width: 22px;
  height: 22px;
  margin: 0 8px 0 0;
}
::ng-deep .stream-members-modal__content__invite__form button {
  font-size: 0.8em;
  padding: 10px;
}
::ng-deep .stream-members-modal__content__invite__form button:disabled {
  border: 1px solid rgba(32, 32, 32, 0);
}
::ng-deep .stream-members-modal__content__invite__form button.button-spinner {
  padding: 10px 10px 10px 38px !important;
}
::ng-deep .stream-members-modal__content__invite__form button.button-spinner:after {
  left: 10px;
}
::ng-deep .stream-members-modal__content__invite__description {
  text-align: center;
  padding: 10px 0;
  margin: 0;
  font-size: 0.9em;
}
::ng-deep .stream-members-modal__content__info {
  margin: 10px 0;
  display: flex;
  align-items: center;
}
@media only screen and (max-width : 736px) {
  ::ng-deep .stream-members-modal__content__info {
    margin: 10px 5px;
  }
}
::ng-deep .stream-members-modal__content__info svg {
  width: 26px;
  height: 26px;
  margin: 0 5px 0 0;
}
::ng-deep .stream-members-modal__content__info p {
  flex: 1;
  font-size: 0.8em;
  color: grey;
  margin: 0;
}
::ng-deep .stream-members-modal__content__buttons {
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}
::ng-deep .stream-members-modal__content__buttons button {
  margin: 0 2px;
  font-size: 0.8em;
}
::ng-deep .stream-members-modal__content__buttons button:disabled {
  border: none;
}
::ng-deep .stream-members-modal__content__list {
  list-style: none;
  margin: 20px 0 30px;
  padding: 0;
}
::ng-deep .stream-members-modal__content__list__counter {
  margin: 0 5px 0 0;
}
::ng-deep .stream-members-modal__content__list__user {
  flex: 1;
  text-decoration: none;
  color: #39aea9;
}
::ng-deep .stream-members-modal__content__list__user:hover {
  color: black;
  text-decoration: none;
}
::ng-deep .stream-members-modal__content__list__actions {
  margin: 0 0 0 10px;
}
@media only screen and (max-width : 736px) {
  ::ng-deep .stream-members-modal__content__list__actions {
    margin: 0 0 0 5px;
    display: flex;
    align-items: center;
  }
}
::ng-deep .stream-members-modal__content__list__actions__status {
  text-transform: uppercase;
  font-size: 0.6em;
  margin: 0 15px;
  display: inline-block;
  text-align: center;
  width: 130px;
  padding: 5px 0;
  border: 1px solid black;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -khtml-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}
@media only screen and (max-width : 736px) {
  ::ng-deep .stream-members-modal__content__list__actions__status {
    margin: 0 10px;
    width: 90px;
  }
}
::ng-deep .stream-members-modal__content__list__actions button {
  font-size: 0.7em;
}
::ng-deep .stream-members-modal__content__list li {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
::ng-deep .stream-members-modal__content__list li:last-child {
  border-bottom: 0;
}
::ng-deep .stream-members-modal__content__message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px 10px 10px;
}
::ng-deep .stream-members-modal__content__message svg {
  width: 250px;
  height: 250px;
}
::ng-deep .stream-members-modal__content__message p {
  padding: 20px 0;
}
::ng-deep .stream-members-modal__content__loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
}
::ng-deep .stream-members-modal__content__loading__spinner {
  position: relative;
  background: rgba(108, 117, 125, 0.1019607843);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -khtml-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  width: 50px;
  height: 50px;
}
::ng-deep .stream-members-modal__content__loading__spinner:after {
  position: absolute;
  content: "";
  border: 2px solid #444444;
  border-color: rgba(68, 68, 68, 0.4705882353) rgba(68, 68, 68, 0.4705882353) transparent transparent;
  width: 25px;
  height: 25px;
  top: 25%;
  left: 25%;
  -webkit-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -moz-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -o-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-25%, -25%);
  -moz-transform: translate(-25%, -25%);
  -ms-transform: translate(-25%, -25%);
  -o-transform: translate(-25%, -25%);
  transform: translate(-25%, -25%);
}
::ng-deep .stream-members-modal__content__loading__label {
  font-family: "Cantarell", sans-serif;
  text-transform: uppercase;
  font-size: 0.7em;
  margin: 10px 0 0;
}
::ng-deep .stream-members-modal__content__info-message {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 0;
}
::ng-deep .stream-members-modal__content__info-message svg {
  width: 30px;
  height: 30px;
  margin: 0 8px 0 0;
}
::ng-deep .stream-members-modal__content__info-message p {
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  color: grey;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

:host {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-lp-content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 20px auto;
  width: 960px;
}
@media only screen and (max-width : 1024px) {
  .main-lp-content {
    width: auto;
    flex: 1;
    margin: 85px 15px 30px !important;
  }
}
@media only screen and (max-width : 736px) {
  .main-lp-content {
    margin: 116px 15px 30px !important;
  }
}
.main-lp-content .lp-headline-main {
  font-family: var(--font-family-palatino);
  font-size: 1.5em;
  margin: 0 0 20px;
  color: #212529;
  text-decoration: none;
}
.main-lp-content .lp-headline-main:hover {
  color: #39aea9;
}
.main-lp-content .search-bar {
  display: flex;
  flex: 1;
  margin: 0 0 30px 0;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
}
@media only screen and (max-width : 1024px) {
  .main-lp-content .search-bar {
    margin: 0 0 15px;
    flex: 1;
  }
}
@media only screen and (max-width : 736px) {
  .main-lp-content .search-bar {
    display: none;
  }
}
.main-lp-content .search-bar.show--bar {
  display: flex;
}
.main-lp-content .search-bar search-content {
  flex: 1;
  z-index: 9;
}
.main-lp-content .search-bar search-content form {
  flex: 100%;
  justify-content: flex-end;
}
.main-lp-content .search-bar search-content form .input-group {
  width: 280px;
}
@media only screen and (max-width : 1024px) {
  .main-lp-content .search-bar search-content form .input-group {
    width: 100%;
  }
}
.main-lp-content .search-bar search-content form input[type=text] {
  background-color: #eaeaea;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -khtml-border-radius: 10px !important;
  -o-border-radius: 10px !important;
  border-radius: 10px !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.main-lp-content .search-bar search-content form input[type=text]:focus {
  background-color: #fff;
}
.main-lp-content .search-bar search-content .preview--mode.preview--active form .input-group {
  width: 100%;
}
.main-lp-content .search-bar search-content .preview--mode.preview--active form .input-group input {
  height: 62px;
  border: 0 none;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
.main-lp-content .search-bar search-content .preview--mode.preview--active form .input-group svg {
  top: 16px !important;
}
.main-lp-content .search-bar search-content .preview--mode.preview--active form .input-group .close-preview-btn {
  top: 14px !important;
  right: 17px !important;
}
.main-lp-content .search-bar search-content .preview--mode.preview--active:before {
  content: "";
}
.main-lp-content .search-bar search-content .preview--mode:before {
  display: none;
  position: fixed;
  background-color: rgba(247, 247, 247, 0.7882352941);
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.main-lp-content .search-bar search-content .preview-list-container {
  top: 74px !important;
  border: 0 none !important;
  box-shadow: initial !important;
}
.main-lp-content .newest-section {
  display: flex;
  flex-direction: column;
  flex: 0 100%;
  margin: 0 0 25px;
}
@media only screen and (max-width : 1024px) {
  .main-lp-content .newest-section {
    margin: -10px 0 50px;
  }
}
.main-lp-content .newest-section .headline-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
@media only screen and (max-width : 1024px) {
  .main-lp-content .newest-section .headline-section {
    flex-direction: column-reverse;
    align-items: stretch;
  }
}
@media only screen and (max-width : 1024px) {
  .main-lp-content .newest-section .headline-section .lp-headline-main {
    position: relative;
    padding: 0;
    margin: 0 0 5px;
  }
}
.main-lp-content .featured-notes-section {
  display: flex;
  flex-direction: column;
  flex: 0 100%;
  margin: 0 0 30px;
}
@media only screen and (max-width : 1024px) {
  .main-lp-content .featured-notes-section {
    margin: 10px 0 40px;
  }
}
.main-lp-content .featured-notes-section__headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 0 20px;
}
.main-lp-content .featured-notes-section__headline h2 {
  font-family: var(--font-family-palatino);
  font-size: 1.5em;
  margin: 10px 0 0;
  color: #212529;
  text-decoration: none;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .featured-notes-section__headline h2 {
    font-size: 1.15em;
  }
}
@media only screen and (max-width : 1024px) {
  .main-lp-content .featured-notes-section__headline {
    flex-direction: column-reverse;
    align-items: stretch;
  }
}
.main-lp-content .featured-notes-section__headline .search-bar {
  margin: 0;
}
.main-lp-content .featured-notes-section__headline .lp-headline-main {
  position: absolute;
}
@media only screen and (max-width : 1024px) {
  .main-lp-content .featured-notes-section__headline .lp-headline-main {
    position: relative;
    padding: 0;
    margin: 0 0 5px;
  }
}
.main-lp-content .featured-notes-section__wrapper {
  display: flex;
  font-family: var(--font-family-georgia);
  padding: 18px 10px;
  background-color: #ecf0f1;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -khtml-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}
.main-lp-content .featured-notes-section__wrapper__note {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0 8px;
  font-size: 0.95em;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .featured-notes-section__wrapper__note {
    padding: 10px 0;
  }
}
.main-lp-content .featured-notes-section__wrapper__note .note-img {
  display: flex;
  margin: 0 25px 0 0;
  width: 120px;
  height: 120px;
  max-width: 120px;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .featured-notes-section__wrapper__note .note-img {
    margin: 0 15px 0 0;
    width: 60px !important;
    height: 60px !important;
    max-width: 60px !important;
  }
}
.main-lp-content .featured-notes-section__wrapper__note .note-details {
  flex: 1;
}
.main-lp-content .featured-notes-section__wrapper__note .note-details .tags {
  margin: 3px 0px;
  line-height: 0.7em;
}
.main-lp-content .featured-notes-section__wrapper__note .note-details .tags .tag {
  text-transform: uppercase;
  font-size: 0.6em;
  display: inline-flex;
  margin: 0 5px 5px 0;
  color: white;
  padding: 3px 5px;
  border-radius: 8px;
  background-color: #39aea9;
}
.main-lp-content .featured-notes-section__wrapper__note .note-details .tags .tag.tag--free-note {
  background-color: #202020;
  border: 1px solid #202020;
  color: white;
}
.main-lp-content .featured-notes-section__wrapper__note .note-details .note-title {
  margin: 0 0 5px;
  font-size: 1.1em;
  cursor: pointer;
  color: #212529;
  text-decoration: none;
}
.main-lp-content .featured-notes-section__wrapper__note .note-details .note-title:hover {
  color: #39aea9;
  border-bottom: 1px solid grey;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .featured-notes-section__wrapper__note .note-details .note-title {
    font-size: 1em;
  }
}
.main-lp-content .featured-notes-section__wrapper__note .note-details .note-description {
  font-size: 0.9em;
  padding: 0;
  margin: 3px 0 8px;
  color: #777272;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.main-lp-content .featured-notes-section__wrapper__note .note-details .other-details {
  display: flex;
  align-items: center;
  margin: 12px 0 0;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .featured-notes-section__wrapper__note .note-details .other-details {
    flex-direction: column;
    align-items: start;
  }
}
.main-lp-content .featured-notes-section__wrapper__note .note-details .other-details .by-user {
  font-family: var(--font-family-palatino);
  display: flex;
  font-size: 0.8em;
  color: #3c3c3c;
  margin: 0 15px 0 0;
  align-items: end;
  text-decoration: none;
}
.main-lp-content .featured-notes-section__wrapper__note .note-details .other-details .by-user .avatar {
  width: 20px;
  height: 20px;
  margin: 0 6px 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
.main-lp-content .featured-notes-section__wrapper__note .note-details .other-details .date {
  font-size: 0.7em;
  color: black;
  margin: 0 0 2px;
  display: none;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .featured-notes-section__wrapper {
    flex-direction: column;
    margin: 0 !important;
    padding: 0 10px;
  }
}
.main-lp-content .mobile-content {
  font-family: var(--font-family-palatino);
  flex: 100;
}
.main-lp-content .mobile-content__loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
}
.main-lp-content .mobile-content__loading__spinner {
  position: relative;
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -khtml-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  width: 50px;
  height: 50px;
}
.main-lp-content .mobile-content__loading__spinner:after {
  position: absolute;
  content: "";
  border: 2px solid #444444;
  border-color: rgba(68, 68, 68, 0.4705882353) rgba(68, 68, 68, 0.4705882353) transparent transparent;
  width: 25px;
  height: 25px;
  top: 25%;
  left: 25%;
  -webkit-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -moz-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -o-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-25%, -25%);
  -moz-transform: translate(-25%, -25%);
  -ms-transform: translate(-25%, -25%);
  -o-transform: translate(-25%, -25%);
  transform: translate(-25%, -25%);
}
.main-lp-content .mobile-content__loading__label {
  font-family: var(--font-family-cantarell);
  text-transform: uppercase;
  font-size: 0.7em;
  margin: 10px 0 0;
}
.main-lp-content .mobile-content__headline {
  font-size: 1.15em;
  margin: 8px 0 20px;
}
.main-lp-content .mobile-content__latest__note {
  display: flex;
  padding: 0 0 20px;
  margin: 0 0 20px;
  word-break: break-word;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0509803922);
}
.main-lp-content .mobile-content__latest__note__details {
  flex: 1;
}
.main-lp-content .mobile-content__latest__note__details__stream {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.main-lp-content .mobile-content__latest__note__details__stream__img ::ng-deep .img-lazy-wrapper {
  margin: 0 6px 0 0;
  height: 24px;
  width: 24px;
}
.main-lp-content .mobile-content__latest__note__details__stream__title {
  font-size: 0.5em;
}
.main-lp-content .mobile-content__latest__note__details__stream__by-user {
  font-size: 0.5em;
  color: #3c3c3c;
  text-decoration: none;
}
.main-lp-content .mobile-content__latest__note__details__stream__separator {
  color: grey;
  font-size: 0.5em;
  margin: 0 5px;
}
.main-lp-content .mobile-content__latest__note__details__stream__profile-img {
  display: inline-flex;
  width: 24px;
  height: 24px;
  margin: 0 6px 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
.main-lp-content .mobile-content__latest__note__details__title {
  display: flex;
  font-size: 0.95em;
  line-height: 1.2;
  font-weight: bold;
  font-family: var(--font-family-georgia);
  margin: 0 0 2px;
  color: rgb(33, 37, 41);
}
.main-lp-content .mobile-content__latest__note__details__date {
  font-family: var(--font-family-cantarell);
  color: #8e9198;
  text-transform: uppercase;
  font-size: 0.75em;
  display: none;
}
.main-lp-content .mobile-content__latest__note__details__tags {
  margin: 7px 0px 0;
  line-height: 0.7em;
}
.main-lp-content .mobile-content__latest__note__details__tags__tag {
  font-family: var(--font-family-cantarell);
  text-transform: uppercase;
  font-size: 0.6em;
  display: inline-flex;
  margin: 0 5px 5px 0;
  color: white;
  padding: 3px 5px;
  border-radius: 8px;
  background-color: #39aea9;
}
.main-lp-content .mobile-content__latest__note__details__tags__tag.tag--free-note {
  background-color: #202020;
  border: 1px solid #202020;
  color: white;
}
.main-lp-content .mobile-content__latest__note__img ::ng-deep .img-lazy-wrapper {
  margin: 0 0 0 10px;
  height: 92px;
  width: 92px;
}
.main-lp-content .load-more-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-lp-content .load-more-content-wrapper .load-more-button {
  font-family: var(--font-family-cantarell);
  color: white;
  text-transform: uppercase;
  font-size: 0.9em;
  padding: 10px 30px;
  min-width: 550px;
  border: 0 none;
  background-color: #39aea9;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -khtml-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .load-more-content-wrapper .load-more-button {
    min-width: 100%;
    flex: 1;
  }
}
.main-lp-content .load-more-content-wrapper .load-more-button:hover {
  color: white;
  background-color: grey;
}
.main-lp-content .load-more-content-wrapper .load-more-button:focus {
  outline: none;
}
.main-lp-content .load-more-content-wrapper .load-more-button.loading {
  position: relative;
  text-indent: -9999px;
}
.main-lp-content .load-more-content-wrapper .load-more-button.loading:after {
  position: absolute;
  content: "";
  border: 2px solid #fff;
  border-color: #fff #fff transparent transparent;
  width: 24px;
  height: 24px;
  top: 25%;
  left: calc(50% - 12px);
  -webkit-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -moz-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -o-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-25%, -25%);
  -moz-transform: translate(-25%, -25%);
  -ms-transform: translate(-25%, -25%);
  -o-transform: translate(-25%, -25%);
  transform: translate(-25%, -25%);
}
.main-lp-content .load-more-content-wrapper .load-more-button.loading:disabled {
  background-color: grey;
}
.main-lp-content .become-a-creator {
  display: flex;
  flex: 100%;
  align-items: center;
  flex-direction: column;
  background-color: #202020;
  padding: 10px;
  margin: 0 0 40px;
  font-family: var(--font-family-georgia);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -khtml-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}
.main-lp-content .become-a-creator .info {
  color: #c7c7c7;
  text-transform: uppercase;
  font-size: 0.8em;
  font-family: var(--font-family-cantarell);
  line-height: 1.3em;
}
.main-lp-content .become-a-creator button {
  display: inline-flex;
  border: 1px solid white;
  padding: 5px 10px;
  margin: 15px 0 0;
  color: white;
  background-color: transparent;
  justify-content: center;
  width: 130px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-family: var(--font-family-cantarell);
  text-transform: uppercase;
  font-size: 0.9em;
}
.main-lp-content .become-a-creator button:hover {
  background-color: white;
  color: black;
}
.main-lp-content .categories-section {
  margin: 0 0 20px;
}
.main-lp-content .column--right .lp-headline-main {
  margin: 0 0 20px 10px;
}
.main-lp-content .create-note-wrapper {
  display: flex;
  flex: 100%;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .create-note-wrapper {
    margin: 0 0 -20px;
  }
}
.main-lp-content .stream-news-container {
  display: flex;
  font-family: var(--font-family-georgia);
  flex-direction: column;
  margin: 0 0 40px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -khtml-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  overflow: hidden;
}
.main-lp-content .stream-news-container .main-thumbnail-info-holder {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px;
}
.main-lp-content .stream-news-container .main-thumbnail-info-holder .thumbnail {
  float: left;
  display: flex;
  width: 210px;
  height: 210px;
  margin: 0 20px 20px 0;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container .main-thumbnail-info-holder .thumbnail {
    float: none;
    display: flex;
    width: 100%;
    min-height: 90vw;
    margin: 0 0 10px;
  }
}
.main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-description {
  position: relative;
}
.main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-description .title {
  margin: 0;
  padding: 0;
  display: none;
}
.main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-description .title .stream-link {
  color: black;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}
.main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-description .title .stream-link:hover {
  color: #39aea9;
}
.main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-description .title .time {
  color: grey;
  margin: 0 20px;
  font-size: 0.85em;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-description .title .time {
    display: none;
  }
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-description .title {
    display: flex !important;
    flex: 1 !important;
    align-items: center !important;
    font-size: 0.85em !important;
  }
}
.main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-description .description {
  margin: 0 0 70px;
  padding: 0;
  line-height: 1.4em;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-description .description {
    margin: 10px 0 !important;
    font-size: 0.9em !important;
    flex: 100% !important;
  }
}
.main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-description:after {
  content: "";
  clear: both;
}
.main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-description .btn-stream-get-more {
  position: absolute;
  bottom: 10px;
  right: 5px;
  font-size: 0.65em;
  text-decoration: none;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-description .btn-stream-get-more {
    display: none !important;
  }
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-description {
    display: flex !important;
    flex-wrap: wrap !important;
    flex: 1 !important;
  }
  .main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-description img-lazy-loading ::ng-deep {
    width: 30px !important;
    height: 30px !important;
    min-height: 30px !important;
    margin: 0 10px 0 0 !important;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -khtml-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
  }
}
.main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-title-link {
  margin: 0 0 5px;
  font-size: 1.1em;
  font-weight: 600;
  font-family: var(--font-family-palatino);
  flex: 0 100%;
  cursor: pointer;
  color: #212529;
  text-decoration: none;
}
.main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-title-link:hover {
  color: rgba(33, 37, 41, 0.7803921569);
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container .main-thumbnail-info-holder .stream-title-link {
    display: none !important;
  }
}
.main-lp-content .stream-news-container .steam-notes-wrapper {
  display: flex;
  margin: 0;
  flex-direction: column;
  border-top: 1px solid rgba(128, 128, 128, 0.168627451);
  padding: 0 15px 10px;
  background-color: #fff;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper {
  display: flex;
  margin: 20px 0;
}
.main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper .note-img {
  display: flex;
  margin: 0 25px 0 0;
  width: 120px;
  height: 120px;
  max-width: 120px;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper .note-img {
    margin: 0 15px 0 0;
    width: 60px !important;
    height: 60px !important;
    max-width: 60px !important;
  }
}
.main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper .note-details {
  flex: 1;
}
.main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper .note-details .tags {
  margin: 3px 0px;
  line-height: 0.7em;
}
.main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper .note-details .tags .tag {
  font-family: var(--font-family-cantarell);
  text-transform: uppercase;
  font-size: 0.6em;
  display: inline-flex;
  margin: 0 5px 5px 0;
  color: white;
  padding: 3px 5px;
  border-radius: 8px;
  background-color: #39aea9;
}
.main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper .note-details .tags .tag.tag--free-note {
  background-color: #202020;
  border: 1px solid #202020;
  color: white;
}
.main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper .note-details .note-title {
  margin: 0 0 5px;
  font-size: 1.1em;
  cursor: pointer;
  color: #212529;
  text-decoration: none;
}
.main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper .note-details .note-title:hover {
  color: #39aea9;
  border-bottom: 1px solid grey;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper .note-details .note-title {
    font-size: 1em;
  }
}
.main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper .note-details .note-description {
  font-size: 0.9em;
  padding: 0;
  margin: 3px 0 8px;
  color: #777272;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper .note-details .other-details {
  display: flex;
  align-items: center;
  margin: 12px 0 0;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper .note-details .other-details {
    flex-direction: column;
    align-items: start;
  }
}
.main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper .note-details .other-details .by-user {
  font-family: var(--font-family-palatino);
  display: flex;
  font-size: 0.8em;
  color: #3c3c3c;
  margin: 0 15px 0 0;
  align-items: end;
  text-decoration: none;
}
.main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper .note-details .other-details .by-user:hover {
  color: #39aea9;
}
.main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper .note-details .other-details .by-user .avatar {
  width: 20px;
  height: 20px;
  margin: 0 6px 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
.main-lp-content .stream-news-container .steam-notes-wrapper .note-wrapper .note-details .other-details .date {
  font-size: 0.7em;
  color: black;
  margin: 0 0 2px;
  display: none;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container .steam-notes-wrapper {
    margin: 0 !important;
    padding: 0 10px !important;
  }
}
.main-lp-content .stream-news-container.grid-type--1 .main-thumbnail-info-holder .stream-description .title {
  margin: 0;
  font-size: 0.85em;
  line-height: 1em;
  color: grey;
}
.main-lp-content .stream-news-container.grid-type--1 .main-thumbnail-info-holder .stream-description .description {
  margin: 0 0 70px;
}
.main-lp-content .stream-news-container.grid-type--2 .main-thumbnail-info-holder .stream-title-link {
  flex: 100%;
}
.main-lp-content .stream-news-container.grid-type--2 .main-thumbnail-info-holder .stream-description .title {
  margin: 0;
  font-size: 0.85em;
  line-height: 1em;
  color: grey;
}
.main-lp-content .stream-news-container.grid-type--2 .main-thumbnail-info-holder .stream-description .description {
  line-height: 1.4em;
  margin: 0 0 55px;
}
.main-lp-content .stream-news-container.grid-type--2 .note-wrapper {
  flex-direction: row-reverse;
}
.main-lp-content .stream-news-container.grid-type--2 .note-wrapper .note-img {
  margin: 0 0 0 25px !important;
}
.main-lp-content .stream-news-container.grid-type--3 .main-thumbnail-info-holder .stream-description .title {
  margin: 0;
  font-size: 0.85em;
  line-height: 1em;
  color: grey;
}
.main-lp-content .stream-news-container.grid-type--3 .main-thumbnail-info-holder .stream-description .description {
  margin: 0 0 70px;
}
.main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper {
  flex-direction: row;
  align-items: flex-start;
}
@media only screen and (max-width : 1024px) {
  .main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper {
    flex-direction: column;
  }
}
.main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper .note-wrapper {
  flex-direction: column;
  flex: 1;
  margin: 10px 40px;
}
@media only screen and (max-width : 1024px) {
  .main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper .note-wrapper {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
  }
}
.main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper .note-wrapper:first-child, .main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper .note-wrapper:last-child {
  margin: 10px 0;
}
.main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper .note-wrapper .note-img {
  display: grid;
  width: 100%;
  max-width: 100%;
  height: 270px;
  margin: 0 0 30px;
}
@media only screen and (max-width : 1024px) {
  .main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper .note-wrapper .note-img {
    margin: 0 15px 0 0;
    width: 120px;
    height: 120px;
    max-width: 120px;
  }
}
.main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper .note-wrapper .other-details {
  flex-direction: column;
  align-items: start !important;
}
.main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper .note-wrapper .other-details .by-user {
  margin: 0 0 5px !important;
}
.main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper.single-note-display .note-wrapper {
  flex-direction: row-reverse;
}
.main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper.single-note-display .note-wrapper .note-img {
  margin: 0 0 0 25px !important;
  width: 120px;
  height: 120px;
  max-width: 120px;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper.single-note-display .note-wrapper .note-img {
    margin: 0 0 0 15px !important;
    width: 100px;
    height: 100px;
    max-width: 100px;
  }
}
.main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper.double--note .note-img {
  height: 455px !important;
}
@media only screen and (max-width : 1024px) {
  .main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper.double--note .note-img {
    height: 125px !important;
  }
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper.double--note .note-img {
    height: 60px !important;
  }
}
.main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper.double--note .note-wrapper:first-child {
  margin: 10px 10px 10px 0;
}
@media only screen and (max-width : 1024px) {
  .main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper.double--note .note-wrapper:first-child {
    margin: 10px 0;
  }
}
.main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper.double--note .note-wrapper:last-child {
  margin: 10px 0 10px 10px;
}
@media only screen and (max-width : 1024px) {
  .main-lp-content .stream-news-container.grid-type--3 .steam-notes-wrapper.double--note .note-wrapper:last-child {
    margin: 10px 0;
  }
}
.main-lp-content .stream-news-container.grid-type--4 {
  flex-direction: initial;
  flex-wrap: wrap;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container.grid-type--4 {
    flex-direction: column;
  }
}
.main-lp-content .stream-news-container.grid-type--4 .main-thumbnail-info-holder {
  flex: 0 330px;
  padding: 15px;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container.grid-type--4 .main-thumbnail-info-holder {
    flex: 1;
  }
}
.main-lp-content .stream-news-container.grid-type--4 .main-thumbnail-info-holder .stream-description {
  height: 100%;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container.grid-type--4 .main-thumbnail-info-holder .stream-description {
    height: auto;
  }
}
.main-lp-content .stream-news-container.grid-type--4 .main-thumbnail-info-holder .stream-description .thumbnail {
  width: 300px;
  height: 300px;
  margin: 0 0 15px;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container.grid-type--4 .main-thumbnail-info-holder .stream-description .thumbnail {
    width: 100%;
    height: inherit;
  }
}
.main-lp-content .stream-news-container.grid-type--4 .main-thumbnail-info-holder .stream-description .btn-stream-get-more {
  display: none;
}
.main-lp-content .stream-news-container.grid-type--4 .main-thumbnail-info-holder .stream-title-link {
  flex: 0;
}
.main-lp-content .stream-news-container.grid-type--4 .steam-notes-wrapper {
  flex: 1;
  border-top: 0 none;
  padding: 30px 15px 15px 20px;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container.grid-type--4 .steam-notes-wrapper {
    padding: 10px;
  }
}
.main-lp-content .stream-news-container.grid-type--4 .steam-notes-wrapper .note-wrapper {
  border-bottom: 1px solid rgba(128, 128, 128, 0.1294117647);
  padding: 0 0 20px;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .stream-news-container.grid-type--4 .steam-notes-wrapper .note-wrapper {
    padding: 0 0 10px;
  }
}
.main-lp-content .stream-news-container.grid-type--4 .steam-notes-wrapper .note-wrapper:last-child {
  border-bottom: 0 none;
}
.main-lp-content .stream-news-container.grid-type--4 .steam-notes-wrapper .note-wrapper .note-img {
  width: 100px;
  height: 100px;
  max-width: 100px;
}
.main-lp-content .stream-news-container .content-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px auto;
}
.main-lp-content .stream-news-container .content-loader__spinner {
  position: relative;
  background: #f3f3f3;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -khtml-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  width: 50px;
  height: 50px;
}
.main-lp-content .stream-news-container .content-loader__spinner:after {
  position: absolute;
  content: "";
  border: 2px solid #444444;
  border-color: rgba(68, 68, 68, 0.4705882353) rgba(68, 68, 68, 0.4705882353) transparent transparent;
  width: 25px;
  height: 25px;
  top: 25%;
  left: 25%;
  -webkit-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -moz-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -o-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-25%, -25%);
  -moz-transform: translate(-25%, -25%);
  -ms-transform: translate(-25%, -25%);
  -o-transform: translate(-25%, -25%);
  transform: translate(-25%, -25%);
}
.main-lp-content .stream-news-container .content-loader__label {
  font-family: var(--font-family-cantarell);
  text-transform: uppercase;
  font-size: 0.7em;
  margin: 10px 0 0;
}
.main-lp-content .populat-categories {
  display: flex;
  flex: 1;
  margin: 0 0 25px;
}
.main-lp-content .populat-categories__list {
  margin: 0;
  padding: 15px 10px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
  background-color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -khtml-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}
.main-lp-content .populat-categories__list__category__label {
  flex: 1;
  padding: 5px 8px;
  font-family: var(--font-family-cantarell);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.7em;
  color: #000;
  cursor: pointer;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -khtml-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px;
}
.main-lp-content .populat-categories__list__category__label:hover {
  color: #8e9198;
}
@media only screen and (max-width : 736px) {
  .main-lp-content .populat-categories {
    display: none;
  }
}

h2 {
  font-size: 28px;
  font-weight: normal;
}

.notd-recent-stream {
  display: flex;
  align-items: stretch;
}
.notd-recent-stream > .item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.notd-recent-stream div {
  position: relative;
}

.no-mobile {
  display: block;
}
@media only screen and (max-width : 736px) {
  .no-mobile {
    display: none;
  }
}

.banner-top {
  position: relative;
  width: 100%;
  min-height: 160px;
  overflow: hidden;
  background-image: url("/assets/bg-1.jpg");
  background-size: cover;
}
@media only screen and (max-width : 972px) {
  .banner-top {
    background-image: url("/assets/bg-1-medium.jpg");
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .banner-top {
    min-height: 80px;
    background-image: url("/assets/bg-1-small.jpg");
  }
}
@media only screen and (min-width: 1441px) {
  .banner-top {
    min-height: 230px;
  }
}
.banner-top .btn-close {
  position: absolute;
  z-index: 3;
  left: 40px;
  top: 15px;
  background: #5fa7a5;
  border-radius: 50%;
  border: 1px solid #49a9a6;
  width: 25px;
  height: 25px;
  font-size: 0.8rem;
  color: #f5f5f5;
  cursor: pointer;
  display: flex;
  justify-content: center;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.banner-top .btn-close:hover {
  transition: all 0.5s;
  background: #2d6f6e;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .banner-top .btn-close {
    left: 4px;
    top: 5px;
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .banner-top .btn-close {
    left: 15px;
  }
}
.banner-top .introduce-section {
  background: rgba(255, 255, 255, 0.8);
  padding: 0 100px;
  margin-top: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .banner-top .introduce-section {
    padding: 0 20px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .banner-top .introduce-section {
    padding: 0;
  }
}
.banner-top .introduce-section .item {
  margin: 15px 0;
}
.banner-top .introduce-section .item .ico {
  text-align: center;
  margin-bottom: 20px;
}
.banner-top .introduce-section .item .ico img {
  width: 50px;
  display: inline-block;
}
.banner-top .content {
  font-weight: 600;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 0 60px;
  padding-top: 30px;
  text-align: center;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .banner-top .content {
    padding: 10px;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .banner-top .content {
    padding: 0 20px;
    padding-top: 30px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .banner-top .content {
    padding: 0 20px;
    padding-top: 30px;
  }
}
.banner-top .content h3 {
  color: white;
  font-size: 2.8em;
  font-weight: 600;
  margin-top: -9px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .banner-top .content h3 {
    font-size: 1em;
  }
}
.banner-top .content p {
  color: white;
  margin-top: 20px;
  font-size: 22px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .banner-top .content p {
    margin-top: 0;
    font-size: 12px;
    margin-bottom: 10px;
  }
}
.banner-top .content .btn {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.55);
  color: #557b83;
  font-size: 1.5em;
  transition: all 0.2s ease-in-out;
}
.banner-top .content .btn:hover {
  background: white;
  transition: all 0.2s ease-in-out;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .banner-top .content .btn {
    font-size: 0.7em;
  }
}
@media only screen and (max-width : 736px) {
  .banner-top h3 {
    font-size: 16px;
    margin: 8px 0 15px;
  }
}
.banner-top .btn-close {
  left: 20px;
}
@media only screen and (max-width : 736px) {
  .banner-top .btn-close {
    width: 25px;
    height: 25px;
    left: 48px;
  }
}
@media only screen and (max-width : 736px) {
  .banner-top .btn-close i {
    left: 0;
    top: 0;
  }
}
@media only screen and (max-width : 736px) {
  .banner-top {
    min-height: 120px;
    border-bottom: 4px solid black;
  }
}

.stream-section {
  padding: 20px;
  display: none;
}
@media only screen and (max-width : 736px) {
  .stream-section {
    display: none;
    overflow: hidden;
    background: linear-gradient(#5e8990, #d9e7c3);
    width: 100%;
  }
}
@media only screen and (max-width : 772px) {
  .stream-section.container {
    margin-top: 0;
  }
}
.stream-section .title {
  text-align: center;
  margin: 10px 0 30px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .stream-section .title {
    margin-bottom: 20px;
    margin-top: 0;
  }
}
.stream-section .form-box .form-control {
  border: none;
  background: none;
  width: 80%;
  display: inline-block;
  border-bottom: 1px solid #c1c1c1;
  border-radius: 0;
}
.stream-section .form-box .btn {
  border: none;
  background: none;
  cursor: pointer;
}
.stream-section .form-box .slide {
  padding: 0 50px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .stream-section .form-box .slide {
    padding: 0;
  }
}
.stream-section .side-nav {
  background: none;
}
.stream-section .side-nav.hide {
  margin-right: 0;
}
.stream-section .side-nav .mobile-toggle-btn {
  left: -40px;
}
.stream-section .side-nav .mobile-toggle-btn.swipe {
  left: 0;
}
.stream-section h2 {
  margin: 0 0 18px;
}
.stream-section h2.header-cat-selector {
  display: flex;
  align-items: center;
}
.stream-section h2.header-cat-selector a {
  color: rgba(0, 0, 0, 0.2);
  font-size: 26px;
  cursor: pointer;
}
@media only screen and (max-width : 736px) {
  .stream-section h2.header-cat-selector a {
    font-size: 0.9rem !important;
    width: 140px;
    line-height: 38px;
    height: 38px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -khtml-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    background: white;
    color: #ad3a3a;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px 2px !important;
    -moz-box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px 2px !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px 2px !important;
  }
  .stream-section h2.header-cat-selector a:hover {
    color: white;
    background: #ad3a3a;
  }
  .stream-section h2.header-cat-selector a.selected {
    color: white !important;
    background: #ad3a3a;
  }
  .stream-section h2.header-cat-selector a:nth-child(2) {
    margin-right: 15px;
  }
}
.stream-section h2.header-cat-selector a.selected {
  color: #000;
  font-size: 30px;
}
@media only screen and (max-width : 736px) {
  .stream-section h2.header-cat-selector a.selected {
    font-size: 18px;
  }
}
.stream-section h2.header-cat-selector .menu-filter {
  display: none;
  width: 36px;
  height: 36px;
  margin-right: 15px;
  background: transparent url("/assets/svg/menu-filter.svg") no-repeat;
  background-size: cover;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.stream-section h2.header-cat-selector .menu-filter:hover {
  background: transparent url("/assets/svg/menu-filter.svg") no-repeat;
}
@media only screen and (max-width : 736px) {
  .stream-section h2.header-cat-selector .menu-filter {
    display: block;
  }
}
.stream-section h2.header-cat-selector .separator {
  background-color: rgba(0, 0, 0, 0.5);
  width: 1px;
  height: 40px;
  font-size: 0;
  margin: 0 16px;
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg);
}
@media only screen and (max-width : 736px) {
  .stream-section h2.header-cat-selector .separator {
    height: 30px;
    display: none;
  }
}
@media only screen and (max-width : 736px) {
  .stream-section h2.header-cat-selector {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
}
@media only screen and (max-width : 736px) {
  .stream-section h2 {
    font-size: 26px;
    text-align: center;
    margin: 0 auto 18px;
  }
}
.stream-section .search-bar {
  display: flex;
  align-items: center;
}
@media only screen and (max-width : 736px) {
  .stream-section .search-bar {
    flex-wrap: wrap;
  }
}
.stream-section .search-bar search-content {
  flex: 1 0;
}
@media only screen and (max-width : 736px) {
  .stream-section .search-bar search-content {
    -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px 2px;
    -moz-box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px 2px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px 2px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -khtml-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
  }
  .stream-section .search-bar search-content input {
    font-size: 1.2rem !important;
  }
}
.stream-section .search-bar button {
  flex: 0 0 auto;
  background-color: #ad3a3a;
  height: 40px;
  margin-left: 18px;
  border: 0 none;
}
@media only screen and (max-width : 736px) {
  .stream-section .search-bar button {
    margin: 17px 0 0;
    font-weight: 300;
    flex: 1 0 auto;
  }
}
.stream-section .streams-list {
  padding: 17px 0 20px;
  display: flex;
  justify-content: unset;
}
@media only screen and (max-width : 736px) {
  .stream-section .streams-list {
    position: relative;
    padding: 17px 0 0;
    height: 390px;
  }
}
.stream-section .streams-list .streams-wrapper {
  flex: 1 1 100%;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width : 736px) {
  .stream-section .streams-list .streams-wrapper {
    display: flex;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    overflow-x: scroll;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -10px;
    padding: 20px 0 0;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
  }
  .stream-section .streams-list .streams-wrapper:not(.is-draggable) {
    -webkit-transition: all 0.15s ease-in-out;
    -moz-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
  }
  .stream-section .streams-list .streams-wrapper::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }
  .stream-section .streams-list .streams-wrapper::-webkit-scrollbar-thumb {
    display: none !important;
  }
}
.stream-section .streams-list .streams-wrapper.is-draggable {
  overscroll-behavior: contain;
}
.stream-section .streams-list .streams-wrapper .stream-container {
  display: flex;
  flex: 0 100%;
  background: #fff;
  padding: 10px;
  margin: 0 0 10px;
  flex-wrap: wrap;
  -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  -moz-box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
}
@media only screen and (max-width : 736px) {
  .stream-section .streams-list .streams-wrapper .stream-container {
    display: none;
  }
}
.stream-section .streams-list .streams-wrapper .stream-container.odd .thumbnail {
  order: 1;
}
.stream-section .streams-list .streams-wrapper .stream-container.odd .notes {
  order: 0;
}
.stream-section .streams-list .streams-wrapper .stream-container.odd .notes .news-wrapper {
  margin: 0 10px 0 0;
}
.stream-section .streams-list .streams-wrapper .stream-container.odd .other-notes-on-stream {
  order: 2;
}
.stream-section .streams-list .streams-wrapper .stream-container .stream-info-wrapper {
  padding: 0 0 10px;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
}
.stream-section .streams-list .streams-wrapper .stream-container .stream-info-wrapper:hover h3, .stream-section .streams-list .streams-wrapper .stream-container .stream-info-wrapper:hover .description {
  color: #557b83;
}
.stream-section .streams-list .streams-wrapper .stream-container .stream-info-wrapper h3 {
  margin: 0;
  font-size: 1.3em;
  font-weight: bold;
}
.stream-section .streams-list .streams-wrapper .stream-container .stream-info-wrapper .description {
  color: #666d7a;
}
.stream-section .streams-list .streams-wrapper .stream-container .thumbnail {
  flex: 0 40%;
  background-size: cover;
  background-position: 50% 50%;
  height: 25vw;
  min-height: 250px;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.stream-section .streams-list .streams-wrapper .stream-container .thumbnail:hover {
  opacity: 0.9;
}
@media only screen and (min-width: 700px) and (max-width: 780px) {
  .stream-section .streams-list .streams-wrapper .stream-container .thumbnail {
    flex: 0 30%;
    min-height: 250px;
  }
}
.stream-section .streams-list .streams-wrapper .stream-container .thumbnail.default {
  background-size: 180px;
  background-repeat: no-repeat;
  background-position: 50% 15%;
  background-color: #e6e6e6;
}
.stream-section .streams-list .streams-wrapper .stream-container .thumbnail .details {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  padding: 20px 10px 10px;
  cursor: pointer;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.stream-section .streams-list .streams-wrapper .stream-container .thumbnail .details:hover .description {
  text-decoration: underline;
}
.stream-section .streams-list .streams-wrapper .stream-container .thumbnail .details h2 {
  font-size: 0.9em;
  font-weight: 600;
  padding: 0 0 3px;
  margin: 0;
}
.stream-section .streams-list .streams-wrapper .stream-container .thumbnail .details .description {
  font-size: 0.8em;
  line-height: 1.7em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.stream-section .streams-list .streams-wrapper .stream-container .thumbnail .more-details {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  background: #557b83;
  padding: 0 5px;
  color: white;
  font-size: 0.8em;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -khtml-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}
.stream-section .streams-list .streams-wrapper .stream-container .thumbnail .more-details .subscribers {
  padding-left: 25px;
  background: url("/../../assets/svg/users-group.svg") 3px 50% no-repeat;
  background-size: 18px;
}
.stream-section .streams-list .streams-wrapper .stream-container .thumbnail .more-details .notes {
  padding-left: 25px;
  background: url("/../../assets/svg/article.svg") 3px 50% no-repeat;
  background-size: 18px;
}
.stream-section .streams-list .streams-wrapper .stream-container .notes {
  flex: 0 60%;
  display: flex;
  justify-content: space-between;
}
@media only screen and (min-width: 700px) and (max-width: 780px) {
  .stream-section .streams-list .streams-wrapper .stream-container .notes {
    flex: 0 70%;
  }
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper {
  flex: 3;
  background-color: #f5f5f5;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin: 0 0 0 10px;
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper:hover {
  background-color: #F0F0F0;
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper.one {
  flex: 1;
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper.two {
  flex: 2;
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper.single-note .thumb {
  display: none;
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper.single-note .title {
  font-size: 1em;
  font-weight: bold;
  padding: 10px 10px 5px;
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper.single-note .note-content-text {
  display: block;
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper .thumb {
  height: 12.5vw;
  margin: 0 0 10px;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper .thumb.default {
  background-size: 105px;
  background-color: rgba(211, 211, 211, 0.4392156863);
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper .thumb.square {
  background-size: cover;
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper .title {
  font-size: 0.8em;
  line-height: 1.3em;
  text-align: left;
  padding: 0;
  margin: 5px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  word-break: break-word;
}
@media only screen and (min-width: 700px) and (max-width: 780px) {
  .stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper .title {
    font-size: 0.7em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    padding: 5px 5px 0;
  }
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper .title:hover {
  text-decoration: none;
  color: #557b83;
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper .note-content-text {
  display: none;
  padding: 10px;
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper .note-content-text p {
  display: -webkit-box;
  font-size: 0.9em;
  color: grey;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  word-break: break-word;
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper .note-content-text img {
  float: left;
  height: 140px;
  margin: 5px 10px 10px 0;
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper .other-details {
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 5px;
  text-align: right;
}
.stream-section .streams-list .streams-wrapper .stream-container .notes .news-wrapper .other-details .created {
  font-size: 0.7em;
  color: #557b83;
  display: inline-block;
}
.stream-section .streams-list .streams-wrapper .stream-container .other-notes-on-stream {
  flex: 1 100%;
  padding: 10px 0 0;
}
.stream-section .streams-list .streams-wrapper .stream-container .other-notes-on-stream h2 {
  display: flex;
  margin: 0 0 10px;
  padding: 0;
  text-transform: uppercase;
  color: #505862;
  font-size: 0.85em;
  font-weight: bold;
}
.stream-section .streams-list .streams-wrapper .stream-container .other-notes-on-stream h2:after {
  background-color: rgba(211, 211, 211, 0.3215686275);
  content: "";
  flex: 1;
  margin: 0 0 0 10px;
}
.stream-section .streams-list .streams-wrapper .stream-container .other-notes-on-stream .note-listed {
  margin: 0 0 10px;
}
.stream-section .streams-list .streams-wrapper .stream-container .other-notes-on-stream .note-listed .note-title-and-content {
  text-decoration: none;
}
.stream-section .streams-list .streams-wrapper .stream-container .other-notes-on-stream .note-listed .note-title-and-content:hover h3, .stream-section .streams-list .streams-wrapper .stream-container .other-notes-on-stream .note-listed .note-title-and-content:hover .small-info {
  color: #39aea9;
}
.stream-section .streams-list .streams-wrapper .stream-container .other-notes-on-stream .note-listed .note-title-and-content h3 {
  color: #22262a;
  font-weight: 500;
  font-size: 0.9em;
  margin: 0;
}
.stream-section .streams-list .streams-wrapper .stream-container .other-notes-on-stream .note-listed .note-title-and-content .small-info {
  color: #666d7a;
  font-size: 0.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media only screen and (min-width: 700px) and (max-width: 780px) {
  .stream-section .streams-list .streams-wrapper .stream-container .other-notes-on-stream .note-listed .note-title-and-content {
    font-size: 0.8em;
  }
}
.stream-section .streams-list .streams-wrapper .stream-container .other-notes-on-stream .note-listed .details {
  display: flex;
  margin: 5px 0 0;
}
.stream-section .streams-list .streams-wrapper .stream-container .other-notes-on-stream .note-listed .details span, .stream-section .streams-list .streams-wrapper .stream-container .other-notes-on-stream .note-listed .details a {
  text-transform: uppercase;
  color: #98a3b3;
  font-size: 0.65em;
  margin: 0 20px 0 0;
  display: inline-block;
}
.stream-section .streams-list .load-more {
  flex: 1 1 100%;
  padding: 5px 0 0;
}
@media only screen and (max-width : 736px) {
  .stream-section .streams-list .load-more {
    display: none;
  }
}
.stream-section .streams-list .load-more .btn {
  background-color: #ad3a3a;
  height: 40px;
  width: 145px;
  font-size: 16px;
  margin: 0 auto;
  border: 0 none;
}
.stream-section .streams-list .load-more .btn:hover {
  background-color: #39aea9;
}
.stream-section .streams-list .arrow {
  display: none;
  position: absolute;
  top: 46%;
  cursor: pointer;
  z-index: 4;
  background: #f5f5f5;
  height: 40px;
  width: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0 1px 6px 0;
  -moz-box-shadow: rgba(0, 0, 0, 0.15) 0 1px 6px 0;
  box-shadow: rgba(0, 0, 0, 0.15) 0 1px 6px 0;
}
.stream-section .streams-list .arrow img {
  height: 30px;
}
@media only screen and (max-width : 736px) {
  .stream-section .streams-list .arrow {
    display: block;
  }
}
.stream-section .streams-list .arrow.left {
  left: -10px;
}
.stream-section .streams-list .arrow.left img {
  margin: 6px 0 0 4px;
}
.stream-section .streams-list .arrow.right {
  right: -10px;
}
.stream-section .streams-list .arrow.right img {
  margin: 6px 0 0 6px;
}
.stream-section .slider-info {
  display: none;
  text-align: center;
}
@media only screen and (max-width : 736px) {
  .stream-section .slider-info {
    display: block;
  }
}
.stream-section .slider-info .circles .circle {
  display: inline-block;
  font-size: 0;
  margin: 0 3px;
  width: 8px;
  height: 8px;
  background: #557b83;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
.stream-section .slider-info .circles .circle.selected {
  background: #39aea9;
}
.stream-section .slider-info .info {
  display: block;
  padding: 0 0 4px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.sign-up-section {
  position: relative;
  overflow: hidden;
}
.sign-up-section .content {
  text-align: center;
  margin: 50px auto 30px;
  color: #fff;
}
@media only screen and (max-width : 736px) {
  .sign-up-section .content {
    width: auto;
    margin: 22px 20px 30px;
  }
}
.sign-up-section .content h2 {
  font-size: 28px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
}
@media only screen and (max-width : 736px) {
  .sign-up-section .content h2 {
    flex-direction: column;
  }
}
.sign-up-section .content h2 .logo {
  margin: 0 0 0 15px;
}
@media only screen and (max-width : 736px) {
  .sign-up-section .content h2 .logo {
    margin: 0;
  }
}
.sign-up-section .content .buttons {
  margin: 50px auto 0;
  width: 560px;
}
@media only screen and (max-width : 736px) {
  .sign-up-section .content .buttons {
    margin: 25px auto 0;
    width: auto;
  }
}
.sign-up-section .content .buttons button {
  display: block;
  font-size: 16px;
  height: 45px;
  width: 100%;
  margin: 0 0 10px;
  border: 0 none;
  color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -khtml-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
  background-color: #000;
}
.sign-up-section .content .buttons button.sign-up {
  background-color: #ad3a3a;
}
.sign-up-section .content .buttons button:hover {
  background-color: #557b83;
}
.sign-up-section .bg {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  z-index: -1;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
@media only screen and (max-width : 736px) {
  .sign-up-section .bg {
    height: 100%;
    width: auto;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}
.sign-up-section.logged-in {
  margin-bottom: -15px;
}
.sign-up-section.logged-in .content {
  margin: 50px auto;
}
.sign-up-section.logged-in .content .buttons {
  display: none;
}

.news-section .content {
  padding: 50px 100px;
}
.news-section .content .grid {
  text-align: left;
  margin: 0 auto;
}
.news-section .content h2 {
  font-size: 28px;
  font-weight: 700;
}
.news-section .content p {
  padding: 0;
  font-size: 16px;
}
.news-section .content .form-signup {
  margin: 65px auto 0;
  width: 660px;
}
.news-section .content .form-signup h2, .news-section .content .form-signup .sign-in {
  font-weight: 700;
}
.news-section .content .form-signup h2 {
  font-size: 24px;
}
.news-section .content .form-signup .sign-in {
  font-size: 20px;
  cursor: pointer;
}
.news-section .content .form-signup .sign-in:hover {
  color: #ad3a3a;
}
.news-section .content .form-signup .form-box-signin input {
  font-size: 14px;
  height: 45px;
}
.news-section .content .form-signup .form-box-signin button {
  font-size: 16px;
  height: 45px;
}

.prelaoder {
  flex: 1 0 100%;
  text-align: center;
  padding: 120px 0;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

:host .user-menu-wrapper {
  list-style: none;
  margin: 0;
  padding: 0;
}
:host .notd-account-btn {
  background: none;
  padding: 0;
  margin: 0 10px 0 10px;
}
:host .notd-account-btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
:host .notd-account-btn span {
  font-size: 14px;
}
:host .notd-account-btn fa-icon {
  font-size: 20px;
  position: relative;
  top: 2px;
}
:host .notd-account-btn:after {
  display: none;
}
:host .notd-account-btn .rounded {
  width: 50px;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -khtml-border-radius: 50% !important;
  -o-border-radius: 50% !important;
  border-radius: 50% !important;
}
:host .profile-submenu li button {
  padding: 10px;
}
:host .touch-device--hide {
  display: flex;
}
@media only screen and (max-width : 1024px) {
  :host .touch-device--hide {
    display: none;
  }
}
:host .touch-device--show {
  display: none;
}
@media only screen and (max-width : 1024px) {
  :host .touch-device--show {
    display: flex;
  }
}
:host share-button {
  display: none;
}
:host .share-img-content {
  display: none;
  justify-content: center;
  flex-direction: column;
  background-image: url("../../../assets/why-publish/bg-intro.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  width: 360px;
  min-height: 600px;
  padding: 15px;
  position: relative;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -khtml-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}
:host .share-img-content h1, :host .share-img-content h2 {
  z-index: 1;
  color: #fff;
  font-family: var(--font-family-titillium);
  font-weight: normal;
  text-align: center;
}
:host .share-img-content h1 {
  font-size: 1.6em;
  line-height: 1.3em;
  margin: 0 0 40px;
}
:host .share-img-content h2 {
  font-size: 1.3em;
  font-weight: 200;
}
:host .share-img-content__logo {
  width: 90px;
  margin: 0 auto 50px;
}

authenticated-user-navbar-element {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 991px) {
  authenticated-user-navbar-element {
    flex-direction: column;
  }
}
authenticated-user-navbar-element notification-button .dropdown-menu {
  left: -180px !important;
}
authenticated-user-navbar-element .notification, authenticated-user-navbar-element .profile {
  min-height: 56px;
}
authenticated-user-navbar-element .notification > div > button, authenticated-user-navbar-element .profile > div > button {
  height: 56px;
}
authenticated-user-navbar-element .notification > div > button > span, authenticated-user-navbar-element .profile > div > button > span {
  top: 6px !important;
}
@media (min-width: 992px) {
  authenticated-user-navbar-element .notification > div, authenticated-user-navbar-element .profile > div {
    position: relative;
    height: 56px;
  }
}
authenticated-user-navbar-element .create-stream {
  margin-left: 10px;
}
@media only screen and (max-width: 991px) {
  authenticated-user-navbar-element .create-stream {
    padding-top: 10px;
    margin-left: 0px;
  }
}
authenticated-user-navbar-element .create-stream a, authenticated-user-navbar-element .create-stream button {
  font-weight: bold;
  max-width: 200px;
}
authenticated-user-navbar-element span {
  white-space: normal;
}
authenticated-user-navbar-element button {
  color: white;
}
authenticated-user-navbar-element button.notd-account-btn .avatar {
  display: inline-flex;
  margin: 2px 0 0;
  width: 50px;
  height: 50px;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
authenticated-user-navbar-element button.notd-account-btn i, authenticated-user-navbar-element button.notd-account-btn span {
  font-weight: 700;
}
authenticated-user-navbar-element button.notd-account-btn span {
  padding-left: 10px;
  padding-right: 10px;
}
authenticated-user-navbar-element .profile > div > button > img, authenticated-user-navbar-element .profile > div > button > svg {
  display: inline-flex;
  max-height: 50px;
  max-width: 50px;
  width: auto;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
}
@media (min-width: 992px) {
  authenticated-user-navbar-element .profile {
    position: relative;
  }
}
authenticated-user-navbar-element .profile .dropdown-toggle fa-icon {
  color: red;
}
authenticated-user-navbar-element .profile .dropdown-menu {
  margin-left: 0px !important;
}
@media (min-width: 992px) {
  authenticated-user-navbar-element .profile .dropdown-menu {
    left: auto;
    width: 220px;
    margin: auto;
    right: 0;
    position: absolute;
  }
}
@media only screen and (max-width: 767px) {
  authenticated-user-navbar-element .profile .dropdown-menu {
    width: auto !important;
  }
}
authenticated-user-navbar-element .notd-dropdown-menu {
  padding: 0;
  min-width: 100%;
  background-color: #efefef;
  border: 0 none;
}
authenticated-user-navbar-element .notd-dropdown-menu .disabled span, authenticated-user-navbar-element .notd-dropdown-menu .disabled fa-icon {
  color: rgb(227, 0, 0);
}
authenticated-user-navbar-element .notd-dropdown-menu > li {
  font-family: var(--font-family-georgia);
  font-size: 14px;
  border-bottom: 0 none;
  padding: 5px;
}
authenticated-user-navbar-element .notd-dropdown-menu > li fa-icon {
  display: inline-block;
  width: 30px;
  font-size: 20px;
  text-align: center;
  margin-right: 5px;
}
authenticated-user-navbar-element .notd-dropdown-menu > li > button {
  font-family: var(--font-family-cantarell);
  font-size: 0.9em;
  text-transform: uppercase;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left !important;
  z-index: 100;
  background: transparent;
}
authenticated-user-navbar-element .notd-dropdown-menu > li > button:hover {
  background-color: white;
}
authenticated-user-navbar-element .notd-dropdown-menu > li > button.custom-svg-icon {
  display: flex;
  align-items: center;
}
authenticated-user-navbar-element .notd-dropdown-menu > li > button.custom-svg-icon svg {
  width: 16px;
  font-size: 20px;
  text-align: center;
  margin: 6px 12px 6px 6px;
}
authenticated-user-navbar-element .notd-dropdown-menu > li .menu--link {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0 10px 10px;
  margin: 0;
  align-items: center;
  font-family: var(--font-family-cantarell);
  font-size: 0.9em;
  text-transform: uppercase;
  color: #212529;
  text-decoration: none;
  cursor: pointer;
}
authenticated-user-navbar-element .notd-dropdown-menu > li .menu--link:hover {
  background-color: #fff;
}
authenticated-user-navbar-element .notd-dropdown-menu > li ul {
  list-style: none;
}
authenticated-user-navbar-element .notd-dropdown-menu > li > ul {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
authenticated-user-navbar-element .notd-dropdown-menu > li > ul li {
  cursor: pointer;
  display: flex;
  align-items: stretch;
}
authenticated-user-navbar-element .notd-dropdown-menu > li > ul li.is-active a {
  color: #ad3a3a;
  font-weight: bold;
}
authenticated-user-navbar-element .notd-dropdown-menu > li > ul li:hover {
  background-color: #f9f9f9;
}
authenticated-user-navbar-element .notd-dropdown-menu > li > ul li a {
  width: 100%;
  text-decoration: none;
  padding: 5px !important;
  color: #292b2c;
  display: block;
  text-align: center !important;
  font-family: var(--font-family-cantarell);
  font-size: 0.8em;
  text-transform: uppercase;
}
authenticated-user-navbar-element .notd-dropdown-menu > li > ul li a .avatar-img {
  position: relative;
  height: 40px;
  width: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
authenticated-user-navbar-element .notd-dropdown-menu > li > ul li a .avatar-img img {
  height: 40px;
  width: auto !important;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -khtml-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  border-radius: 0px !important;
}
authenticated-user-navbar-element .notd-dropdown-menu > li > ul li a > img {
  width: 40px !important;
  padding-right: 4px;
  margin: auto;
}
authenticated-user-navbar-element .notd-dropdown-menu > li > ul li a > div, authenticated-user-navbar-element .notd-dropdown-menu > li > ul li a > span {
  line-height: 16px;
  padding: 5px;
  word-break: break-word;
}
@media only screen and (max-width: 991px) {
  authenticated-user-navbar-element .notd-dropdown-menu > li > ul > li {
    flex-basis: 50%;
    flex-grow: 1;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  authenticated-user-navbar-element .notd-dropdown-menu > li > ul > li {
    flex-basis: 33%;
    flex-grow: 1;
  }
}
@media only screen and (min-width: 992px) {
  authenticated-user-navbar-element .notd-dropdown-menu > li {
    position: static;
  }
  authenticated-user-navbar-element .notd-dropdown-menu > li > ul {
    max-height: 301px;
    overflow: auto;
    background-color: transparent;
    opacity: 0;
    flex-direction: row;
    visibility: hidden;
    justify-content: flex-right;
    position: absolute;
    top: 0;
    right: 0%;
    padding: 0px;
    min-width: 400px;
  }
  authenticated-user-navbar-element .notd-dropdown-menu > li > ul li {
    flex-basis: 33%;
    flex-grow: 0;
    background-color: white;
    border: 0 none;
  }
  authenticated-user-navbar-element .notd-dropdown-menu > li:hover > ul {
    opacity: 1;
    right: 100%;
    visibility: visible;
  }
}
authenticated-user-navbar-element .notd-dropdown-menu > li:first-child {
  border-style: none;
}
authenticated-user-navbar-element .stream-list li {
  position: relative;
}
authenticated-user-navbar-element .community-stream {
  color: rgb(155, 155, 155);
  position: absolute;
  right: 2px;
  top: 2px;
  font-size: 15px !important;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

:host {
  display: flex;
  height: 100%;
  flex-direction: column;
  --font-1: Georgia, Palatino, "Times New Roman", Times, serif;
  --color-blue-1: #39aea9;
}
:host .container {
  flex: 1 0 auto;
}
@media only screen and (max-width : 772px) {
  :host .content-wrapper {
    overflow: hidden;
  }
}
:host .display--flex {
  display: flex;
}
:host .display--flex .split--50p {
  flex: 0 50%;
}
:host .display--flex .m--r--20 {
  margin: 0 20px 0 0;
}
@media only screen and (max-width : 1024px) {
  :host .user-profile-page {
    flex-direction: column;
  }
}
:host .user-profile-page__content {
  font-family: var(--font-1);
  display: flex;
  flex: 1;
  flex-direction: column;
}
:host .user-profile-page__content__profile {
  display: flex;
}
@media only screen and (max-width : 1024px) {
  :host .user-profile-page__content__profile {
    flex-direction: column;
  }
}
:host .user-profile-page__content__profile__avatar {
  flex: 0 230px;
  margin: 0 30px 0 20px;
}
@media only screen and (max-width : 1024px) {
  :host .user-profile-page__content__profile__avatar {
    flex: 1;
    margin: 30px;
    justify-content: center;
    align-items: center;
  }
}
:host .user-profile-page__content__profile__form {
  flex: 1;
}
:host .user-profile-page__content__profile__form__section__headline {
  font-size: 1.25em;
  margin: 20px 0 10px;
}
:host .user-profile-page__content__profile__form__input-field {
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
}
:host .user-profile-page__content__profile__form__input-field__label {
  margin: 0 0 10px;
  font-size: 0.95em;
  font-family: var(--font-1);
}
:host .user-profile-page__content__profile__form__input-field__field {
  font-family: var(--font-1);
  font-size: 1em;
  padding: 12px;
  border: 1px solid rgba(128, 128, 128, 0.1607843137);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -khtml-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}
@media only screen and (max-width : 736px) {
  :host .user-profile-page__content__profile__form__input-field__field {
    width: 100%;
  }
}
:host .user-profile-page__content__profile__form__input-field__field.textarea {
  resize: none;
  height: 100px;
}
:host .user-profile-page__content__profile__form__button {
  margin: 25px 0;
  display: flex;
  justify-content: right;
}
@media only screen and (max-width : 1024px) {
  :host .user-profile-page__content__profile__form__button {
    justify-content: center;
  }
}
:host .user-profile-page__content__profile__form__button button {
  font-size: 0.9em;
}
:host .user-profile-page__content__profile__form__button button:disabled {
  border: 0 none;
}
:host .user-profile-page__content__profile__form__errors {
  display: flex;
  flex-direction: column;
}
:host .user-profile-page__content__profile__form__errors__error {
  font-size: 0.85em;
  margin: 10px 0;
  display: flex;
  align-items: center;
}
:host .user-profile-page__content__profile__form__errors__error svg {
  width: 22px;
  height: 22px;
  margin: 0 8px 0 0;
}
:host .user-profile-page__content__h2 {
  font-size: 1.25em;
}
:host .user-profile-page__content__activity {
  margin: 30px 0;
  padding: 30px 0 0;
  border-top: 1px solid rgba(128, 128, 128, 0.168627451);
}
:host .user-profile-page__content__subsriptions {
  margin: 35px 0;
}
:host .user-profile-page .user-email__address {
  display: flex;
  align-items: center;
}
:host .user-profile-page .user-email__address svg {
  width: 20px;
  margin: 0 10px 0 0;
}
:host .user-profile-page .user-email__info {
  display: flex;
  margin: 7px 0;
  align-items: center;
  font-size: 0.9em;
  color: var(--color-blue-1);
}
:host .user-profile-page .user-email__info svg {
  width: 20px;
  margin: 0 10px 0 0;
}
:host .user-profile-page .user-notifications {
  margin: 20px 0;
}
:host .user-profile-page .user-notifications__checkbox {
  display: flex;
  align-items: center;
  font-size: 1em;
  cursor: pointer;
  margin: 12px 0;
}
:host .user-profile-page .user-notifications__checkbox svg {
  margin: 0 10px 0 0;
}
:host .user-profile-page .user-notifications__checkbox svg path {
  fill: var(--color-blue-1);
}
:host .user-profile-page .user-location {
  margin: 20px 0;
}
:host .user-profile-page .user-location notd-address {
  margin: 15px 0;
  display: block;
}
:host .user-profile-page .user-location__distance {
  display: flex;
  align-items: center;
  margin: 15px 0;
}
:host .user-profile-page .user-location__distance__label {
  margin: 0 15px 0 0;
}
:host .user-profile-page .user-location__distance toggle-switch {
  margin: 0 10px;
}
:host .user-profile-page .user-location__distance--selected {
  color: var(--color-blue-1);
}
:host .user-profile-page .user-delete__link {
  color: var(--color-blue-1);
  cursor: pointer;
}
:host .user-profile-page .user-delete__link:hover {
  text-decoration: underline;
}
:host .user-profile-page .user-delete__info {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 0.8em;
  color: grey;
}
:host .user-profile-page .user-delete__info svg {
  margin: 0 8px 0 0;
  width: 20px;
  height: 20px;
}

app-user {
  display: flex;
  height: 100%;
  flex-direction: column;
}
app-user .container {
  flex: 1 0 auto;
}
app-user .save-section {
  border-style: solid none none;
  border-width: 1px;
  border-color: rgba(91, 81, 81, 0.078);
}
app-user ::ng-deep notd-address .buttons-container .locator-button {
  padding: 0.5rem 2px;
}
app-user .popover-header {
  display: none;
}
app-user input[type=checkbox] {
  display: none;
}
app-user .email-section {
  margin-top: 20px;
  display: flex;
  flex-flow: wrap;
}
app-user .error {
  border: 1px solid #ad3a3a;
}
app-user .form-error {
  display: none;
  padding: 3px 0 2px;
  font-size: 13px;
  color: #ad3a3a;
}
app-user .form-error.show {
  display: block !important;
}
app-user .label-check {
  cursor: pointer;
}
app-user .label-check .ng-fa-icon {
  color: #557b83;
  width: 24px;
  margin-right: 5px;
}
app-user .distance-measure .active {
  color: #d12d2d;
}
app-user .distance-measure span {
  padding-left: 5px;
  padding-right: 5px;
}
app-user label:not(.label-check) {
  font-size: 14px;
  margin: 0;
  position: relative;
}
app-user .user-data-wrapper {
  display: flex;
}
app-user .user-data-wrapper .img-container {
  flex: 0 1 200px;
  margin: 0 15px;
}
@media only screen and (max-width : 772px) {
  app-user .user-data-wrapper .img-container .profile-avatar {
    margin: 0 auto;
  }
}
@media only screen and (max-width : 772px) {
  app-user .user-data-wrapper .img-container {
    flex: 1 1 auto;
    margin: 0 15px;
  }
}
app-user .user-data-wrapper .user-details {
  flex: 1 1;
  margin-right: 15px;
}
@media only screen and (max-width : 772px) {
  app-user .user-data-wrapper .user-details {
    flex: 1 1 auto;
    margin: 0 15px;
  }
}
app-user .user-image-avatar {
  height: 202px;
  width: 202px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}
@media only screen and (max-width : 1024px) {
  app-user .user-image-avatar {
    height: 170px;
    width: 170px;
  }
}
app-user .user-image-avatar .default-image {
  height: 100%;
  width: auto;
  position: absolute;
  top: 0;
  left: 50%;
  min-width: 100%;
  object-fit: cover;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -khtml-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
}
app-user .user-image-avatar-default .show-editor-modal {
  color: #423230 !important;
}
app-user .error-message {
  display: block;
  text-align: center;
  color: #ad3a3a;
  font-size: 14px;
  font-weight: bold;
  margin: 4px 0;
}
app-user .subscription-payment-wrapper {
  display: none;
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 0;
  max-height: 2000px;
  margin: 0 0 15px;
  overflow: hidden;
  -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  -moz-box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
app-user .subscription-payment-wrapper .payment-content-wrapper {
  display: none;
  margin: 0 auto;
  border: 0 none;
  padding: 0 50px;
  -webkit-transition: opacity 0.2s ease-in-out 0.2s;
  -moz-transition: opacity 0.2s ease-in-out 0.2s;
  -ms-transition: opacity 0.2s ease-in-out 0.2s;
  -o-transition: opacity 0.2s ease-in-out 0.2s;
  transition: opacity 0.2s ease-in-out 0.2s;
  font-size: 0.85em;
  font-weight: bold;
}
app-user .subscription-payment-wrapper .payment-content-wrapper .message {
  padding: 0 0 0 10px;
}
app-user .subscription-payment-wrapper .payment-content-wrapper fa-icon {
  color: #39aea9;
}
app-user .subscription-payment-wrapper .payment-content-wrapper.error {
  display: flex;
  color: #f24f5d;
}
app-user .subscription-payment-wrapper .payment-content-wrapper.error .message {
  color: #000;
  padding: 0 0 0 6px;
}
app-user .subscription-payment-wrapper .payment-content-wrapper.error .message .reload-page {
  color: #39aea9;
  text-decoration: underline;
  font-weight: 0.8em;
  cursor: pointer;
}
app-user .subscription-payment-wrapper .payment-content-wrapper.error .message .reload-page:hover {
  color: #000;
}
app-user .subscription-payment-wrapper iframe {
  width: 100%;
  height: 100%;
  border: 0 none;
  display: none;
}
app-user .subscription-payment-wrapper iframe .pub-brand {
  display: none;
}
app-user .subscription-payment-wrapper.show {
  display: flex;
  height: 90px !important;
  justify-content: center;
  align-items: center;
}
app-user .subscription-payment-wrapper.show iframe {
  display: block;
}
app-user .subscription-payment-wrapper.show .payment-content-wrapper {
  display: none;
}
app-user .subscription-payment-wrapper.error {
  display: flex;
  min-height: 90px;
  justify-content: center;
  align-items: center;
}
app-user .subscription-payment-wrapper.error iframe {
  display: none;
}
app-user .subscription-payment-wrapper.error .payment-content-wrapper {
  display: flex;
}
app-user .subscription-payment-wrapper.resize-and-purchase {
  min-height: 90px;
  height: 450px;
  display: block;
}
app-user .subscription-payment-wrapper.resize-and-purchase .payment-content-wrapper {
  display: none;
}
app-user .subscription-payment-wrapper.resize-and-purchase iframe {
  display: block;
}

.user-email .email {
  font-weight: bold;
}
.user-email .instructions {
  font-size: 13px;
  color: #557b83;
}
.user-email a {
  color: #557b83;
}
.user-email > div:first-child > span:first-child {
  padding-right: 5px;
}

@media only screen and (max-width: 576px) {
  .notd-table-header {
    min-width: 800px;
  }
  .notd-table-row {
    min-width: 800px;
  }
}
@media only screen and (max-width : 772px) {
  .table-holder {
    overflow: auto;
  }
}

.profile-avatar {
  height: 202px;
  width: 202px;
  margin: 25px 0 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.profile-avatar img {
  height: 100%;
  width: auto;
  position: absolute;
  top: 0;
  left: 50%;
  min-width: 100%;
  object-fit: cover;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -khtml-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
}
@media only screen and (max-width : 736px) {
  .profile-avatar {
    height: 170px;
    width: 170px;
    margin: 0 auto;
  }
}

.notd-profile .title {
  margin: 0px;
  padding: 0px 0px 10px 0px;
  color: #ad3a3a;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}
.notd-profile .location, .notd-profile .since {
  font-weight: bold;
  font-size: 14px;
}
.notd-profile .location span, .notd-profile .since span {
  padding-right: 4px;
}
.notd-profile .location {
  text-align: right;
}
.notd-profile .earnings-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notd-profile .earnings-col div {
  padding-right: 4px;
}
.notd-profile .earnings-row {
  display: flex;
  align-items: center;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

:host {
  overflow: auto;
}
:host table {
  margin: 10px 0;
  min-width: 800px;
}
:host table thead {
  background-color: rgba(128, 128, 128, 0.0705882353);
}
@media only screen and (max-width : 736px) {
  :host table thead tr {
    display: table-row;
  }
}
:host table thead tr th {
  text-align: center;
  padding: 14px 5px;
}
:host table thead tr th.row-costs {
  width: 200px;
}
:host table thead tr th.row-extend {
  width: 150px;
}
:host table thead tr th.label {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  color: #5d6277;
  font-size: 0.9em;
  font-weight: 500;
  margin: 0 15px 0 0;
  cursor: pointer;
}
@media only screen and (max-width : 736px) {
  :host table tbody tr {
    display: table-row;
  }
}
:host table tbody tr td .stream-link:hover {
  color: black;
  text-decoration: none;
}
:host table tbody tr td .button--disabled {
  border: none;
  font-size: 0.75em;
}
:host .no-subscriptions-results {
  background: #fff;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -khtml-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}
:host .no-subscriptions-results fa-icon {
  font-size: 2em;
  margin: 0 15px;
  color: #3aada8;
}
:host .no-subscriptions-results p {
  margin: 0;
  padding: 0;
}

subscriptions-table {
  display: grid;
}
subscriptions-table .expired {
  color: red;
}
subscriptions-table a {
  color: #557b83;
}
subscriptions-table .price-col > span:nth-child(2) {
  color: red;
  padding-left: 3px;
}
subscriptions-table .button-col {
  padding-left: 0;
  padding-right: 0;
}

.notd-table .notd-table-header {
  padding: 0 5px;
}
.notd-table .notd-table-row {
  padding: 5px;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
}
.loading__spinner {
  position: relative;
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -khtml-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  width: 50px;
  height: 50px;
}
.loading__spinner:after {
  position: absolute;
  content: "";
  border: 2px solid #444444;
  border-color: rgba(68, 68, 68, 0.4705882353) rgba(68, 68, 68, 0.4705882353) transparent transparent;
  width: 25px;
  height: 25px;
  top: 25%;
  left: 25%;
  -webkit-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -moz-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -o-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-25%, -25%);
  -moz-transform: translate(-25%, -25%);
  -ms-transform: translate(-25%, -25%);
  -o-transform: translate(-25%, -25%);
  transform: translate(-25%, -25%);
}
.loading__label {
  font-family: "Cantarell", sans-serif;
  text-transform: uppercase;
  font-size: 0.7em;
  margin: 10px 0 0;
}

::ng-deep .subscribe-to-stream-modal {
  font-family: var(--font-family-georgia);
}
::ng-deep .subscribe-to-stream-modal .modal-dialog .modal-content {
  position: relative;
  padding: 20px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px 0px !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px 0px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px 0px !important;
}
@media only screen and (max-width : 736px) {
  ::ng-deep .subscribe-to-stream-modal .modal-dialog .modal-content {
    padding: 10px;
  }
}
::ng-deep .subscribe-to-stream-modal__close-btn {
  display: inline-flex;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
::ng-deep .subscribe-to-stream-modal__close-btn svg {
  height: 26px;
  width: 26px;
}
::ng-deep .subscribe-to-stream-modal__close-btn svg:hover {
  opacity: 0.6;
}
::ng-deep .subscribe-to-stream-modal__title {
  font-size: 1.4em;
  text-align: center;
  margin: 0;
  padding: 0;
}
@media only screen and (max-width : 736px) {
  ::ng-deep .subscribe-to-stream-modal__title {
    padding: 0 45px 0 0;
  }
}
::ng-deep .subscribe-to-stream-modal__content__progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  margin: 40px 0;
  width: 100%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -khtml-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}
::ng-deep .subscribe-to-stream-modal__content__progress__content {
  display: flex;
  align-items: center;
  justify-content: center;
}
::ng-deep .subscribe-to-stream-modal__content__progress__content__spinner {
  position: relative;
  background: #f4f4f4;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -khtml-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  margin: 0 15px 0 0;
}
::ng-deep .subscribe-to-stream-modal__content__progress__content__spinner:after {
  position: absolute;
  content: "";
  border: 2px solid #444444;
  border-color: rgba(68, 68, 68, 0.4705882353) rgba(68, 68, 68, 0.4705882353) transparent transparent;
  width: 25px;
  height: 25px;
  top: 25%;
  left: 25%;
  -webkit-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -moz-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -o-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-25%, -25%);
  -moz-transform: translate(-25%, -25%);
  -ms-transform: translate(-25%, -25%);
  -o-transform: translate(-25%, -25%);
  transform: translate(-25%, -25%);
}
::ng-deep .subscribe-to-stream-modal__content__progress__content__label {
  font-family: var(--font-family-cantarell);
  text-transform: uppercase;
  font-size: 0.75em;
  margin: 0;
}
::ng-deep .subscribe-to-stream-modal__content__progress.error {
  display: flex;
}
::ng-deep .subscribe-to-stream-modal__content__progress.error svg {
  width: 60px;
  height: 60px;
  margin: 0 20px 0 0;
}
::ng-deep .subscribe-to-stream-modal__content__text {
  margin: 20px 0 10px;
}
::ng-deep .subscribe-to-stream-modal__content__info {
  margin: 10px 0;
  display: flex;
  align-items: center;
}
@media only screen and (max-width : 736px) {
  ::ng-deep .subscribe-to-stream-modal__content__info {
    margin: 10px 5px;
  }
}
::ng-deep .subscribe-to-stream-modal__content__info svg {
  width: 26px;
  height: 26px;
  margin: 0 5px 0 0;
}
::ng-deep .subscribe-to-stream-modal__content__info p {
  flex: 1;
  font-size: 0.8em;
  color: grey;
  margin: 0;
}
::ng-deep .subscribe-to-stream-modal__content__buttons {
  display: flex;
  justify-content: center;
  z-index: 1;
}
::ng-deep .subscribe-to-stream-modal__content__buttons button {
  margin: 0 2px;
  font-size: 0.8em;
}
::ng-deep .subscribe-to-stream-modal__content__buttons button:disabled {
  border: none;
}
::ng-deep .subscribe-to-stream-modal__content__list {
  list-style: none;
  margin: 20px 0 30px;
  padding: 0;
}
::ng-deep .subscribe-to-stream-modal__content__list__counter {
  margin: 0 5px 0 0;
}
::ng-deep .subscribe-to-stream-modal__content__list__user {
  flex: 1;
  text-decoration: none;
  color: #39aea9;
}
::ng-deep .subscribe-to-stream-modal__content__list__user:hover {
  color: black;
  text-decoration: none;
}
::ng-deep .subscribe-to-stream-modal__content__list__actions {
  margin: 0 0 0 10px;
}
::ng-deep .subscribe-to-stream-modal__content__list__actions button {
  font-size: 0.7em;
}
::ng-deep .subscribe-to-stream-modal__content__list li {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
::ng-deep .subscribe-to-stream-modal__content__list li:last-child {
  border-bottom: 0;
}
::ng-deep .subscribe-to-stream-modal__content__message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px 10px 10px;
}
::ng-deep .subscribe-to-stream-modal__content__message svg {
  width: 250px;
  height: 250px;
}
::ng-deep .subscribe-to-stream-modal__content__message p {
  padding: 20px 0;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

.bank-mode-switch span, .bank-mode-switch button, .bank-mode-switch div {
  font-weight: bold;
  color: #557b83;
}
.bank-mode-switch .link {
  cursor: pointer;
}
.bank-mode-switch .link:hover {
  color: #ad3a3a;
}

notd-banking-form .bank-type label {
  color: gray;
}
notd-banking-form .bank-type h6 {
  font-weight: bold;
}
notd-banking-form label {
  cursor: pointer;
}
notd-banking-form label > span {
  padding-left: 20px;
  padding-right: 5px;
}
notd-banking-form label .ng-fa-icon {
  font-weight: bold;
  margin: 0 3px;
}
notd-banking-form label.active {
  color: #ad3a3a;
}
notd-banking-form input[type=radio] {
  display: none;
  cursor: pointer;
  outline: 0;
}
notd-banking-form input[type=radio] + label {
  cursor: pointer;
  margin-left: 5px;
  margin-right: 10px;
}
notd-banking-form label {
  position: relative;
}
notd-banking-form label::after {
  position: absolute;
  top: 4px;
  left: calc(100% + 4px);
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
}
notd-banking-form label.valid::after {
  content: "\f058";
  color: rgb(0, 128, 0);
}
notd-banking-form label.required::after {
  content: "\f069";
  color: rgb(173, 58, 58);
  font-size: 10px;
}
notd-banking-form label .validation-icon {
  font-size: 18px;
  color: #39aea9;
}
notd-banking-form label .validation-icon.required {
  color: #ad3a3a;
}
notd-banking-form input.ng-invalid.ng-dirty, notd-banking-form input.ng-invalid.ng-dirty:focus {
  background: rgba(255, 0, 0, 0.1);
}
notd-banking-form .change-account-link {
  margin-left: 20px;
  font-size: 12px;
  color: #ad3a3a !important;
  cursor: pointer;
}
notd-banking-form .change-account-link:hover {
  color: #557b83 !important;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

notd-bank-transfer .notd-fee {
  font-weight: bold;
}
notd-bank-transfer .notd-fee span {
  padding-right: 5px;
}
notd-bank-transfer .bank-address {
  border-right: 1px solid rgba(91, 81, 81, 0.5);
}
@media only screen and (max-width : 736px) {
  notd-bank-transfer .bank-address {
    border-right: 0 none;
    margin-bottom: 20px;
  }
}
notd-bank-transfer .bank-address h3 {
  font-size: 16px;
  color: #557b83;
}
notd-bank-transfer .bank-address p {
  margin: 0;
}
notd-bank-transfer label {
  font-size: 14px;
  margin: 0;
}
notd-bank-transfer .bank-mode-switch button {
  margin: 5px;
}
notd-bank-transfer .bank-info {
  width: 100%;
}
notd-bank-transfer .bank-info h3 {
  font-size: 16px;
  color: #557b83;
}
notd-bank-transfer .bank-info p {
  margin: 0;
}
notd-bank-transfer .bank-info .strong {
  font-weight: bold;
}
notd-bank-transfer .bank-info > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
notd-bank-transfer .bank-info > div > div {
  word-break: break-word;
}
notd-bank-transfer price-field input {
  font-weight: bold;
  font-size: 20px;
}
notd-bank-transfer .modal-header {
  background: transparent;
  color: black;
}
notd-bank-transfer .modal-header .modal-title {
  color: black;
}
notd-bank-transfer .modal-header button.close {
  background: transparent;
  opacity: 1;
  color: black;
}
notd-bank-transfer .modal-header button.close:hover {
  color: #ad3a3a;
}
notd-bank-transfer .modal-header button.close[disabled] {
  color: #cbcbcb;
}
notd-bank-transfer .amount-line {
  align-items: center;
}
notd-bank-transfer .amount-line .user-balance {
  display: flex;
  align-items: baseline;
}
notd-bank-transfer .amount-line .user-balance .total-earnings {
  margin: 0 0 0 10px;
}
notd-bank-transfer .amount-line h4 {
  font-weight: normal;
}
notd-bank-transfer .transfer-section {
  margin-top: 20px;
  margin-bottom: 10px;
  border-style: none none solid none;
}
notd-bank-transfer .transfer-section input.ng-invalid.ng-dirty, notd-bank-transfer .transfer-section input.ng-invalid.ng-dirty:focus {
  background: rgba(255, 0, 0, 0.1);
}
notd-bank-transfer h3, notd-bank-transfer h4, notd-bank-transfer h5, notd-bank-transfer h6 {
  font-weight: bold;
}
notd-bank-transfer .modal-body button {
  min-width: 100px;
}
notd-bank-transfer .move-right {
  text-align: right;
}
notd-bank-transfer .move-right .ml {
  margin-left: 10px;
}
notd-bank-transfer .info-box {
  margin: 0 0 15px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  text-align: center;
  padding: 10px 60px;
  background: rgba(0, 0, 0, 0.02);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
notd-bank-transfer .info-box .ng-fa-icon {
  position: absolute;
  font-size: 40px;
  left: 25px;
  top: 28px;
}
notd-bank-transfer .info-box p {
  padding: 0;
  margin: 0 0 5px;
}
notd-bank-transfer .info-box button:hover {
  background: #557b83;
}
notd-bank-transfer .form-buttons {
  margin: 0 15px;
}
notd-bank-transfer .form-buttons button {
  margin: 0 10px 0 0;
}

.notd-user-docs-wrapper {
  padding-right: 0;
  margin: 0;
  padding-left: 0;
}

.notd-user-docs {
  display: flex;
  flex-direction: column !important;
}
.notd-user-docs h5, .notd-user-docs h6 {
  position: relative;
}
.notd-user-docs h5 i, .notd-user-docs h6 i {
  font-size: 30px;
  color: green;
}
.notd-user-docs > div:last-child {
  display: flex;
}
.notd-user-docs > div:last-child > div {
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  flex-wrap: wrap;
}
.notd-user-docs > div:last-child > div > div {
  width: 100%;
  padding: 10px;
}
.notd-user-docs > div:last-child .file-display-container > div.file-displayer {
  flex-basis: calc(100% - 100px);
}
.notd-user-docs > div:last-child .file-display-container > div.file-button {
  flex-basis: 100px;
}
@media only screen and (max-width: 991px) {
  .notd-user-docs > div:last-child {
    flex-direction: column !important;
  }
  .notd-user-docs > div:last-child .file-display-container > div.file-displayer {
    flex-basis: 100%;
  }
  .notd-user-docs > div:last-child .file-display-container > div.file-button {
    flex-basis: 100%;
  }
}
.notd-user-docs p {
  padding: 10px 0;
}
.notd-user-docs .notd-doc-uploader uploader-field .file-upload label[for], .notd-user-docs .notd-doc-uploader button {
  height: 34px;
  padding: 0px 10px;
  cursor: pointer;
  line-height: 34px;
}
.notd-user-docs .notd-doc-uploader uploader-field {
  margin-bottom: 10px;
}
.notd-user-docs .notd-doc-uploader .doc-spin {
  padding-bottom: 10px;
}
.notd-user-docs .notd-doc-uploader uploader-field .file-upload label.file-display {
  min-height: 30px;
}
.notd-user-docs .notd-doc-uploader p {
  padding: 10px 0;
  margin: 0;
}

.total-earnings {
  color: #557b83;
  font-size: 20px;
  font-weight: bold;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

notd-legal-info label {
  position: relative;
}
notd-legal-info label::after {
  position: absolute;
  top: 4px;
  left: calc(100% + 4px);
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
}
notd-legal-info label.valid::after {
  content: "\f058";
  color: rgb(0, 128, 0);
}
notd-legal-info label.required::after {
  content: "\f069";
  color: rgb(173, 58, 58);
  font-size: 10px;
}
notd-legal-info input.ng-invalid.ng-dirty, notd-legal-info input.ng-invalid.ng-dirty:focus {
  background: rgba(255, 0, 0, 0.1);
}
notd-legal-info .validation-icon {
  font-size: 18px;
  color: #39aea9;
}
notd-legal-info .validation-icon.required {
  color: #ad3a3a;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

:host {
  display: flex;
  height: 100%;
  flex-direction: column;
  --font-1: Georgia, Palatino, "Times New Roman", Times, serif;
  --color-blue-1: #39aea9;
}
:host .container {
  flex: 1 0 auto;
}
@media only screen and (max-width : 772px) {
  :host .content-wrapper {
    overflow: hidden;
  }
}
@media only screen and (max-width : 1024px) {
  :host .notd-earnings-page {
    flex-direction: column;
  }
}
:host .notd-earnings-page__content {
  font-family: var(--font-1);
  display: flex;
  flex: 1;
  flex-direction: column;
}
:host .profile-details {
  display: flex;
}
@media only screen and (max-width : 972px) {
  :host .profile-details {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width : 972px) {
  :host .profile-details .profile-avatar {
    margin: 10px auto 15px;
  }
}
:host .profile-details .side-details {
  display: flex;
  margin-left: 10px;
  flex: 1 0 auto;
}
@media only screen and (max-width : 972px) {
  :host .profile-details .side-details {
    flex: 1 0 auto;
  }
}
:host .profile-details .side-details .user-title, :host .profile-details .side-details .user-location {
  flex: 1 0;
}
:host .profile-details .side-details .user-location {
  text-align: right;
  font-size: 15px;
  font-weight: bold;
}
:host .stream-earnings {
  margin: 0 auto 20px;
}
:host .earnings-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width : 736px) {
  :host .earnings-row {
    flex-wrap: wrap;
  }
}
:host .earnings-row select {
  flex: 0 0 140px;
}
@media only screen and (max-width : 736px) {
  :host .earnings-row select {
    flex: 1 0 auto;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width : 1024px) {
  :host .earnings-row select {
    flex: 0 135px;
    padding: 0 5px;
  }
}
@media only screen and (max-width : 1024px) {
  :host .earnings-row .total-earnings {
    font-size: 0.8em;
    font-family: var(--font-1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
:host .earnings-row .total-earnings span {
  color: #5b5151;
  font-size: 1rem;
  display: inline-block;
  margin-right: 15px;
}
@media only screen and (max-width : 736px) {
  :host .earnings-row .total-earnings {
    flex: 1 0 70%;
  }
}
@media only screen and (max-width : 736px) {
  :host .earnings-row button {
    flex: 1 0 30%;
  }
}
:host .earnings-row .transfer-btn {
  height: auto;
  font-size: 0.8em;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

:host {
  display: flex;
  height: 100%;
  flex-direction: column;
  --font-1: Georgia, Palatino, "Times New Roman", Times, serif;
  --color-blue-1: #39aea9;
}
:host .container {
  flex: 1 0 auto;
}
@media only screen and (max-width : 772px) {
  :host .content-wrapper {
    overflow: hidden;
  }
}
@media only screen and (max-width : 1024px) {
  :host .notd-team-page {
    flex-direction: column;
  }
}
:host .notd-team-page__content {
  display: flex;
  flex: 1;
  flex-direction: column;
}
:host .notd-team-page__content__loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
}
:host .notd-team-page__content__loading__spinner {
  position: relative;
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -khtml-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  width: 50px;
  height: 50px;
}
:host .notd-team-page__content__loading__spinner:after {
  position: absolute;
  content: "";
  border: 2px solid #444444;
  border-color: rgba(68, 68, 68, 0.4705882353) rgba(68, 68, 68, 0.4705882353) transparent transparent;
  width: 25px;
  height: 25px;
  top: 25%;
  left: 25%;
  -webkit-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -moz-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -o-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-25%, -25%);
  -moz-transform: translate(-25%, -25%);
  -ms-transform: translate(-25%, -25%);
  -o-transform: translate(-25%, -25%);
  transform: translate(-25%, -25%);
}
:host .notd-team-page__content__loading__label {
  font-family: "Cantarell", sans-serif;
  text-transform: uppercase;
  font-size: 0.7em;
  margin: 10px 0 0;
}
:host .notd-team-page__content__info {
  background: #fff;
  padding: 25px;
  margin: 20px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--font-1);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -khtml-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}
:host .notd-team-page__content__info fa-icon {
  font-size: 2em;
  margin: 0 15px 0 5px;
  color: #3aada8;
}
:host .notd-team-page__content__info p {
  margin: 0;
  padding: 0;
}
:host .notd-team-page__content__create-team {
  background: #fff;
  padding: 20px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-1);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -khtml-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}
:host .notd-team-page__content__create-team fa-icon {
  font-size: 2.2em;
  margin: 0;
  color: #96a3a3;
}
:host .notd-team-page__content__create-team p {
  margin: 0;
  padding: 0;
}
:host .notd-team-page__content__create-team button {
  font-size: 0.7em;
  margin: 15px;
}
:host .notd-team-page__content__team-form {
  display: flex;
  flex-direction: column;
}
:host .team-page__content {
  font-family: var(--font-1);
}
:host .team-page__content__table__title {
  font-family: var(--font-family-georgia);
  font-weight: normal;
  font-size: 1.05em;
  margin: 0 0 12px;
}
:host .team-page__content__table table {
  margin: 10px 0;
  min-width: 800px;
  font-family: var(--font-1);
}
:host .team-page__content__table table thead {
  background-color: rgba(128, 128, 128, 0.0705882353);
}
@media only screen and (max-width : 736px) {
  :host .team-page__content__table table thead tr {
    display: table-row;
  }
}
:host .team-page__content__table table thead tr th {
  text-align: center;
  padding: 14px 5px;
}
:host .team-page__content__table table thead tr th.row-costs {
  width: 200px;
}
:host .team-page__content__table table thead tr th.row-extend {
  width: 260px;
}
:host .team-page__content__table table thead tr th.label {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  color: #5d6277;
  font-size: 0.9em;
  font-weight: 500;
  margin: 0 15px 0 0;
  cursor: pointer;
}
@media only screen and (max-width : 736px) {
  :host .team-page__content__table table tbody tr {
    display: table-row;
  }
}
:host .team-page__content__table table tbody tr td .link {
  color: var(--color-blue-1);
}
:host .team-page__content__table table tbody tr td .link.black--link {
  color: var(--color-black-1);
}
:host .team-page__content__table table tbody tr td .link.black--link:hover {
  color: grey;
}
:host .team-page__content__table table tbody tr td .link:hover {
  color: black;
  text-decoration: none;
}
:host .team-page__content__table table tbody tr td .stream-title-wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
}
:host .team-page__content__table table tbody tr td .stream-title-wrapper__img {
  margin: 0 10px 0 0;
}
:host .team-page__content__table table tbody tr td .stream-title-wrapper__img img {
  width: 45px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -khtml-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}
:host .team-page__content__table table tbody tr td .stream-status {
  font-size: 0.8em;
  font-weight: bold;
}
:host .team-page__content__table table tbody tr td button {
  font-size: 0.65em;
}
:host .team-page__content__invite__form .team-page__form__input-wrapper {
  flex-direction: initial;
  align-items: center;
  margin: 0;
}
:host .team-page__content__invite__form .team-page__form__input-wrapper button {
  font-size: 0.7em;
}
:host .team-page__content__invite__form .team-page__form__input-wrapper input {
  margin: 0 10px;
  width: 240px;
}
:host .team-page__content__invite__form .team-page__form__input-wrapper__error {
  margin: 0;
}
:host .team-page__form {
  margin: 30px 0;
  font-family: var(--font-1);
}
:host .team-page__form__input-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 0 15px;
}
:host .team-page__form__input-wrapper__label {
  margin: 0 0 2px;
  font-family: var(--font-family-georgia);
  font-size: 1.25em;
}
:host .team-page__form__input-wrapper label {
  margin: 0 0 2px;
  font-size: 1em;
}
:host .team-page__form__input-wrapper__info {
  color: #777272;
  font-family: var(--font-family-georgia);
  font-size: 0.8em;
  margin: 0px 0 14px;
}
:host .team-page__form__input-wrapper__input, :host .team-page__form__input-wrapper__textarea {
  font-family: var(--font-family-georgia);
  line-height: initial;
  padding: 8px 5px;
  font-size: 0.9em;
}
:host .team-page__form__input-wrapper__textarea {
  line-height: 1.3em;
}
:host .team-page__form__input-wrapper__textarea + .chart-input-counter {
  top: 0px !important;
  right: 0px !important;
}
:host .team-page__form__input-wrapper__error {
  font-size: 0.85em;
  margin: 10px 0;
  display: flex;
  align-items: center;
}
:host .team-page__form__input-wrapper__error svg {
  width: 22px;
  height: 22px;
  margin: 0 8px 0 0;
}
:host .team-page__form__input-wrapper.custom-url label {
  display: flex;
  align-items: center;
}
:host .team-page__form__input-wrapper.custom-url label toggle-switch {
  margin: 0 0 0 15px;
}
:host .team-page__form__input-wrapper .custom-url__input-wrapper {
  display: flex;
  margin: 5px 0;
}
:host .team-page__form__input-wrapper .custom-url__input-wrapper input {
  flex: 1;
}
:host .team-page__form__buttons {
  text-align: left;
  margin: 20px 0;
}
:host .team-page__form__buttons button {
  font-size: 0.75em;
  margin: 0 10px 0 0;
}
:host .team-page__form__buttons button:disabled {
  border: 0 none !important;
}
:host .team-page__team-subs-table-wrapper {
  margin: 0 0 30px;
  overflow: auto;
}
:host .team-page__team-subs-table-wrapper .team-subs-table {
  width: 100%;
  border: 0;
}
:host .team-page__team-subs-table-wrapper .team-subs-table tr {
  border-bottom: 1px solid rgba(214, 214, 214, 0.7294117647);
}
@media only screen and (max-width : 736px) {
  :host .team-page__team-subs-table-wrapper .team-subs-table tr {
    display: grid;
  }
}
:host .team-page__team-subs-table-wrapper .team-subs-table tr:last-child {
  border-bottom: 0 none;
}
:host .team-page__team-subs-table-wrapper .team-subs-table tr td {
  vertical-align: middle;
  text-align: center;
  padding: 10px;
}
:host .team-page__team-subs-table-wrapper .team-subs-table__type__name {
  font-family: var(--font-family-cantarell);
  font-size: 0.9em;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}
:host .team-page__team-subs-table-wrapper .team-subs-table__price__input {
  font-family: var(--font-family-cantarell);
  font-size: 0.9em;
  line-height: 1.5em;
  padding: 5px;
  border: 1px solid rgba(214, 214, 214, 0.7294117647);
  width: 120px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
:host .team-page__team-subs-table-wrapper .team-subs-table__price__input.input--error {
  border: 1px solid darkred;
}
:host .team-page__team-subs-table-wrapper .team-subs-table__price__info {
  display: block;
  padding: 5px 0 0;
  font-size: 0.7em;
}
:host .team-page__team-subs-table-wrapper .team-subs-table__price__info.info-error {
  color: darkred;
}
:host .team-page__team-subs-table-wrapper .team-subs-table__status__label {
  padding: 4px 10px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -khtml-border-radius: 14px;
  -o-border-radius: 14px;
  border-radius: 14px;
  color: #fff;
  text-align: center;
  display: inline-block;
  font-family: var(--font-family-cantarell);
  font-size: 0.8em;
  text-transform: uppercase;
}
:host .team-page__team-subs-table-wrapper .team-subs-table__status__label.label--active {
  background-color: red;
}
:host .team-page__team-subs-table-wrapper .team-subs-table__status__label.label--disabled {
  background-color: lightgrey;
}
:host .team-page__team-subs-table-wrapper .team-subs-table__actions {
  text-align: right;
}
:host .team-page__team-subs-table-wrapper .team-subs-table__actions button {
  padding: 6px 10px;
  font-size: 0.8em;
  color: #fff;
  text-align: center;
  display: inline-block;
  border: 0 none;
  margin: 5px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -khtml-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}
:host .team-page__team-subs-table-wrapper .team-subs-table__actions button:disabled {
  background-color: rgba(228, 228, 228, 0.73) !important;
}
:host .team-page__team-subs-table-wrapper .team-subs-table__actions button.button--black {
  background-color: black;
}
:host .team-page__team-subs-table-wrapper .team-subs-table__actions button.button--black:hover {
  background-color: rgba(115, 115, 115, 0.73);
}
:host .team-page__team-subs-table-wrapper .team-subs-table__actions button.button--white {
  color: black;
  border: 1px solid rgba(214, 214, 214, 0.7294117647);
}
:host .team-page__team-subs-table-wrapper .team-subs-table__actions button.button--white:hover {
  background-color: rgba(228, 228, 228, 0.73);
}
:host .team-page__team-subs-table-wrapper .team-subs-table__actions button.button--blue {
  background-color: #17a5ae;
}
:host .team-page__team-subs-table-wrapper .team-subs-table__actions button.button--blue:hover {
  background-color: rgba(192, 192, 192, 0.73);
}
:host .team-page__team-subs-table-wrapper .team-subs-table__actions button.button--red {
  background-color: red;
}
:host .team-page__team-subs-table-wrapper .team-subs-table__actions button.enabled--state {
  background-color: #000;
}

notd-team h5 {
  font-weight: bold;
}
notd-team h2 {
  font-size: 0.9em;
  font-weight: bold;
}
notd-team h2.underline {
  border-bottom: 1px solid #ced4da;
}
notd-team .message {
  font-size: 0.95em;
  margin: -5px 0 10px;
}
notd-team .validation-icon {
  font-size: 18px;
  color: #39aea9;
}
notd-team .validation-icon.required {
  color: #ad3a3a;
}
notd-team .info-message {
  display: flex;
  margin: 0 0 10px;
  padding: 2px 10px;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ced4da;
  background: rgba(206, 212, 218, 0.1);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
notd-team .info-message .ng-fa-icon {
  color: #557b83;
  font-size: 24px;
}
notd-team .info-message p {
  margin: 0 6px;
  font-size: 14px;
}
notd-team .btn {
  border: 0 none;
  background-color: #557b83;
  color: #fff;
  font-size: 15px;
  margin: 0;
}
notd-team .btn:hover {
  background-color: #ad3a3a;
}
notd-team .btn.red {
  background-color: #ad3a3a;
}
notd-team .btn.red:hover {
  background-color: #557b83;
}
notd-team .mb-10 {
  margin-bottom: 10px;
}
notd-team .mt-10 {
  margin-top: 10px;
}
notd-team .ml-10 {
  margin-left: 10px;
}
notd-team .table-wrapper {
  padding: 0 0 20px;
}
@media only screen and (max-width : 736px) {
  notd-team .table-wrapper {
    overflow: auto;
  }
}
notd-team .table-wrapper table {
  width: 100%;
  border: 1px solid #d6d6d6;
  background-color: #fff;
  margin-bottom: 10px;
  overflow: hidden;
  -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  -moz-box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -khtml-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
@media only screen and (max-width : 736px) {
  notd-team .table-wrapper table {
    width: 800px;
  }
}
notd-team .table-wrapper table .link {
  color: #557b83;
}
notd-team .table-wrapper table thead th {
  background: rgba(214, 214, 214, 0.4784313725);
  padding: 3px;
  text-align: center;
}
notd-team .table-wrapper table tbody tr {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  background: none;
}
notd-team .table-wrapper table tbody tr td {
  text-align: center;
  font-size: 0.8rem;
  padding: 3px 1px;
  border-bottom: 1px solid rgba(214, 214, 214, 0.7294117647);
}
notd-team .table-wrapper table tbody tr td.note-name {
  max-width: 180px;
}
notd-team .table-wrapper table tbody tr td.location {
  max-width: 140px;
}
notd-team .table-wrapper table tbody tr td.options {
  min-width: 80px;
}
notd-team .table-wrapper table tbody tr:hover {
  background: rgba(245, 245, 245, 0.8509803922);
}
notd-team .table-wrapper table tbody tr .btn {
  padding: 2px 3px;
  margin: 1px 2px;
  font-size: 12px;
}
notd-team .table-wrapper table tbody tr .btn:hover {
  background: #557b83;
}
notd-team .table-wrapper table tbody tr .tag {
  background: #557b83;
  color: #fff;
  font-size: 11px;
  padding: 2px 5px;
  margin: 1px;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -khtml-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
notd-team .table-wrapper .show-more .btn {
  font-size: 16px;
  color: white;
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -ms-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}
notd-team .table-wrapper .show-more .btn:hover {
  background: #557b83;
}
notd-team team-plans h5 {
  font-size: 0.9em;
  font-weight: bold;
  border-bottom: 1px solid #ced4da;
}
notd-team team-plans .header-row, notd-team team-plans .notd-table-header {
  background: rgba(214, 214, 214, 0.4784313725);
  padding-top: 2px;
  padding-bottom: 2px;
}
notd-team team-plans .header-row div, notd-team team-plans .notd-table-header div {
  color: #000 !important;
}
notd-team notd-team-members-table h5 {
  font-size: 1.1em;
  color: #000;
  font-weight: bold;
  border-bottom: 1px solid #ced4da;
}
notd-team notd-team-members-table .notd-table-header {
  background: rgba(214, 214, 214, 0.4784313725) !important;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #000 !important;
  align-items: center;
}
notd-team .invite-form input {
  flex: 0 250px;
  margin-right: 5px;
}
@media only screen and (max-width : 736px) {
  notd-team .invite-form input {
    flex: auto;
  }
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

:host .file-info-message {
  font-size: 14px;
}
:host .file-info-message .ng-fa-icon {
  margin: 0 3px;
}
:host .file-info-message span {
  color: #557b83;
}
:host .file-info-message a {
  color: #557b83;
  font-weight: bold;
  cursor: pointer;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

notd-id-doc button, notd-tax-doc button {
  height: 40px;
}
notd-id-doc uploader-field, notd-tax-doc uploader-field {
  margin-bottom: 10px;
}
notd-id-doc .doc-spin, notd-tax-doc .doc-spin {
  padding-bottom: 10px;
}
notd-id-doc .uploader-label, notd-tax-doc .uploader-label {
  display: none;
}

:host .file-info-message {
  font-size: 14px;
}
:host .file-info-message fa-icon {
  margin: 0 3px;
}
:host .file-info-message span {
  color: #557b83;
}
:host .file-info-message a {
  color: #557b83;
  font-weight: bold;
  cursor: pointer;
}
:host .notd-doc-uploader h6 {
  margin: 25px 0 0;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

toggle-switch .switcher {
  display: inline-block;
  background-color: #acacac;
  padding: 2px;
  width: 38px;
  line-height: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -khtml-border-radius: 12px;
  -o-border-radius: 12px;
  border-radius: 12px;
  cursor: pointer;
}
toggle-switch .switcher .circle {
  background-color: #fff;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-size: 0;
  line-height: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
toggle-switch .switcher.check {
  background-color: #39aea9;
}
toggle-switch .switcher.check .circle {
  margin-left: 14px;
}
toggle-switch .switcher.disabled {
  opacity: 0.45;
  background-color: #acacac;
}
toggle-switch.black-white .switcher {
  background-color: white;
  border-radius: 20px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  width: 40px;
}
toggle-switch.black-white .switcher .circle {
  background-color: black !important;
}
toggle-switch.black-white .switcher.check {
  background-color: white;
}
toggle-switch.black-white .switcher.check .circle {
  margin-left: 14px;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

@media only screen and (min-width: 768px) {
  profile-comment-box {
    width: 49.4%;
  }
}
@media only screen and (max-width: 767px) {
  profile-comment-box {
    width: 100%;
  }
}
profile-comment-box .profile-comment-box {
  position: relative;
}
profile-comment-box .search-item {
  width: 100% !important;
  border: 1px solid #d6d6d6;
  -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  -moz-box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -khtml-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
profile-comment-box .spin-container {
  padding-top: 20px;
}
profile-comment-box .search-item-content {
  padding: 10px;
}
profile-comment-box .thread-container {
  padding-top: 4px;
  padding-bottom: 4px;
}
profile-comment-box .title2 {
  padding-top: 2px;
  flex-basis: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 14px;
  color: #557b83;
  display: flex;
  justify-content: space-between;
}
profile-comment-box .title2 .author1 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
profile-comment-box .title2 .date1 {
  flex-basis: 100px;
  flex-shrink: 0;
  padding-left: 4px;
  flex-grow: 1;
  /* display: flex; */
  text-align: right;
}
profile-comment-box .search-item-header {
  height: 64px;
  display: flex;
  border-bottom: 1px solid #dddddd;
}
profile-comment-box .search-item-header h3 {
  /*Thanks to http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/*/
  overflow: hidden;
  position: relative;
  line-height: 1.3em;
  max-height: 2.6em;
  text-align: left;
  margin-right: 0em;
  padding-right: 16px;
  word-break: break-word;
}
profile-comment-box .search-item-header h3:before {
  content: "...";
  position: absolute;
  right: 0;
  bottom: 5px;
  width: 16px;
  height: 16px;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1px;
  background: transparent;
  z-index: 1;
}
profile-comment-box .search-item-header h3:after {
  content: "";
  position: absolute;
  right: 0;
  width: 16px;
  height: 2.6em;
  margin-top: 0;
  background: white;
  z-index: 2;
}
profile-comment-box .description {
  min-height: 6.5em;
  /*Thanks to http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/*/
  overflow: hidden;
  position: relative;
  line-height: 1.3em;
  max-height: 6.5em;
  text-align: left;
  margin-right: 0em;
  padding-right: 16px;
  word-break: break-word;
}
profile-comment-box .description:before {
  content: "...";
  position: absolute;
  right: 0;
  bottom: 5px;
  width: 16px;
  height: 16px;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1px;
  background: transparent;
  z-index: 1;
}
profile-comment-box .description:after {
  content: "";
  position: absolute;
  right: 0;
  width: 16px;
  height: 6.5em;
  margin-top: 0;
  background: white;
  z-index: 2;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

@media only screen and (min-width: 768px) {
  profile-rating-box {
    width: 49.4%;
  }
}
@media only screen and (max-width : 772px) {
  profile-rating-box {
    width: 100%;
  }
}
profile-rating-box .profile-rating-box {
  position: relative;
}
profile-rating-box .profile-rating-box .head h3 {
  margin-bottom: 0;
}
@media only screen and (max-width : 736px) {
  profile-rating-box .profile-rating-box .head h3 {
    margin-left: 10px;
  }
}
profile-rating-box .profile-rating-box .rating-details .date {
  display: block;
}
profile-rating-box .spin-container {
  padding-top: 20px;
}
profile-rating-box .search-item {
  width: 100% !important;
  border: 1px solid #d6d6d6;
  -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  -moz-box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -khtml-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
profile-rating-box .search-item-content {
  padding: 10px;
}
profile-rating-box .thread-container {
  padding-top: 4px;
  padding-bottom: 4px;
}
profile-rating-box .title2 {
  padding-top: 2px;
  flex-basis: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 14px;
  color: #557b83;
  display: flex;
  justify-content: space-between;
}
profile-rating-box .title2 .author1 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
profile-rating-box .search-item-header {
  height: 64px;
  display: flex;
  border-bottom: 1px solid #dddddd;
}
profile-rating-box .search-item-header h3 {
  /*Thanks to http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/*/
  overflow: hidden;
  position: relative;
  line-height: 1.3em;
  max-height: 2.6em;
  text-align: left;
  margin-right: 0em;
  padding-right: 16px;
  word-break: break-word;
}
profile-rating-box .search-item-header h3:before {
  content: "...";
  position: absolute;
  right: 0;
  bottom: 5px;
  width: 16px;
  height: 16px;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1px;
  background: transparent;
  z-index: 1;
}
profile-rating-box .search-item-header h3:after {
  content: "";
  position: absolute;
  right: 0;
  width: 16px;
  height: 2.6em;
  margin-top: 0;
  background: white;
  z-index: 2;
}
profile-rating-box .description-container {
  height: 90px;
}
@media only screen and (max-width : 772px) {
  profile-rating-box .description-container {
    height: auto;
  }
}
profile-rating-box .description-container .rating-subject {
  font-size: 15px;
  margin: 2px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
profile-rating-box .description-container .description {
  font-size: 14px;
  /*Thanks to http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/*/
  overflow: hidden;
  position: relative;
  line-height: 1.3em;
  max-height: 5.2em;
  text-align: left;
  margin-right: 0em;
  padding-right: 16px;
  word-break: break-word;
}
profile-rating-box .description-container .description:before {
  content: "...";
  position: absolute;
  right: 0;
  bottom: 5px;
  width: 16px;
  height: 16px;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1px;
  background: transparent;
  z-index: 1;
}
profile-rating-box .description-container .description:after {
  content: "";
  position: absolute;
  right: 0;
  width: 16px;
  height: 5.2em;
  margin-top: 0;
  background: white;
  z-index: 2;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

notification-button {
  position: relative;
}
notification-button .dropdown-menu {
  margin-top: 2px !important;
}
notification-button .show > .dropdown-menu {
  display: block;
}
notification-button .notifications-dropdown-list {
  font-family: var(--font-family-georgia);
  position: absolute;
  padding: 0;
  top: 46px !important;
  left: auto !important;
  right: -70px !important;
  width: 380px;
  background-color: #efefef;
  border: 0 none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
}
@media only screen and (max-width : 772px) {
  notification-button .notifications-dropdown-list {
    position: fixed !important;
    top: 50px !important;
    right: 20px !important;
    left: 20px !important;
    width: auto;
  }
}
@media only screen and (min-width : 1460px) {
  notification-button .notifications-dropdown-list {
    width: 700px;
  }
}
notification-button .notifications-dropdown-list:before {
  display: none;
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #f6f6f6;
  top: -8px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
@media only screen and (max-width : 772px) {
  notification-button .notifications-dropdown-list:before {
    left: auto;
    right: 55px;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}
@media only screen and (max-width : 772px) {
  notification-button .notifications-dropdown-list:before {
    right: 54px;
  }
}
@media only screen and (min-width : 1460px) {
  notification-button .notifications-dropdown-list:before {
    left: auto;
    right: 181px;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}
notification-button .notifications-dropdown-list .header {
  padding: 12px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d6d6d6;
  outline: 0 !important;
  text-align: left;
  list-style: none;
  background-color: #efefef;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
notification-button .notifications-dropdown-list .header .fa {
  font-size: 14px;
}
notification-button .notifications-dropdown-list .header .title-name {
  text-transform: uppercase;
  font-family: var(--font-family-cantarell);
  color: #000;
  font-size: 0.9em;
  font-weight: bold;
}
notification-button .notifications-dropdown-list .header .links {
  display: flex;
  font-size: 0.8em;
}
notification-button .notifications-dropdown-list .header .links a {
  color: #557b83;
  text-decoration: none;
  margin-left: 8px;
  cursor: pointer;
}
notification-button .notifications-dropdown-list .header .links a:hover {
  color: #ad3a3a;
}
notification-button .notifications-dropdown-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
  max-height: 470px;
  scroll-behavior: smooth;
}
@media only screen and (max-width : 772px) {
  notification-button .notifications-dropdown-list ul {
    max-height: 70vh;
  }
}
@media only screen and (min-width : 1460px) {
  notification-button .notifications-dropdown-list ul {
    max-height: 70vh;
  }
}
notification-button .notifications-dropdown-list ul li {
  border-bottom: 1px solid rgba(214, 214, 214, 0.2784313725);
  outline: 0 !important;
}
notification-button .notifications-dropdown-list ul li .loader {
  flex: 0 0 45px;
  text-indent: -10px;
}
notification-button .notifications-dropdown-list ul li a {
  padding: 20px;
  font-size: 0.85em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: #557b83;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
@media only screen and (max-width : 736px) {
  notification-button .notifications-dropdown-list ul li a {
    font-size: 1.15em;
  }
}
notification-button .notifications-dropdown-list ul li a .thumbnail {
  width: 46px;
  margin-right: 12px;
  outline: 0 !important;
  display: none;
}
@media only screen and (min-width : 1460px) {
  notification-button .notifications-dropdown-list ul li a .thumbnail {
    width: 66px;
  }
}
notification-button .notifications-dropdown-list ul li a .n-name {
  font-size: 0.8em;
  text-align: left;
  flex: 1 1 auto;
  line-height: 1.2;
  color: #000;
  white-space: normal;
  outline: 0 !important;
  text-decoration: none;
}
@media only screen and (min-width : 1460px) {
  notification-button .notifications-dropdown-list ul li a .n-name {
    font-size: 1.1em;
  }
}
notification-button .notifications-dropdown-list ul li a.image-loaded .thumbnail {
  display: block;
}
notification-button .notifications-dropdown-list ul li a.image-loaded .loader {
  display: none;
}
notification-button .notifications-dropdown-list ul li a:hover {
  background-color: #f9f9f9;
}
notification-button .notifications-dropdown-list .bottom {
  color: #000;
  display: block;
  text-align: center;
  font-size: 14px;
  padding: 8px 0;
  border-top: 1px solid #d6d6d6;
}
notification-button .notifications-dropdown-list .arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
}
notification-button .notifications-dropdown-list .notifications__empty {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -khtml-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
}
notification-button .notifications-dropdown-list .notifications__empty fa-icon {
  font-size: 4em;
  margin: 10px auto;
  line-height: 1em;
  color: #bababa;
}
notification-button .dropdown-toggle svg {
  color: #3aada8 !important;
  font-size: 18px;
}
notification-button .notifications-total {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  background: #c32c02;
  left: 30px;
  top: -2px;
  font-size: 12px;
}
notification-button .notifications-total.big {
  font-size: 11px;
  width: 22px;
  height: 22px;
}
notification-button .dropdown-toggle::after {
  display: none;
}
notification-button .notifications-button {
  background-color: transparent;
  position: relative;
  width: 60px;
  outline: 0;
  border-style: none;
}
notification-button .notifications-button:focus {
  box-shadow: none;
}
notification-button .notifications-button:hover {
  background: none;
}
notification-button .notifications-button:hover fa-icon svg path {
  fill: #3FCFCA;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

.annotation-cloud {
  position: absolute;
  background: #ececec;
  width: 240px;
  padding: 5px;
  z-index: 4;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -khtml-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
  -webkit-animation: show-annotation 0.2s forwards;
  -moz-animation: show-annotation 0.2s forwards;
  -o-animation: show-annotation 0.2s forwards;
  animation: show-annotation 0.2s forwards;
}
.annotation-cloud textarea, .annotation-cloud .text-area {
  height: 60px;
  color: #202020;
  font-family: var(--font-family-georgia);
  font-size: 13px;
  line-height: 1.2;
  resize: none;
  outline: none;
  padding: 3px;
  width: 100%;
  border: 0 none;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -khtml-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
  background: white;
}
.annotation-cloud textarea a, .annotation-cloud .text-area a {
  color: #ad3a3a;
}
.annotation-cloud .buttons {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
}
.annotation-cloud .buttons button {
  flex: 1;
  font-family: var(--font-family-cantarell);
  font-size: 10px;
  text-transform: uppercase;
  background-color: #202020;
  color: #fff;
  margin: 0 2px;
  cursor: pointer;
}
.annotation-cloud .buttons button:hover {
  color: #202020;
  border: 1px solid #202020;
  background-color: #fff;
}
.annotation-cloud:after {
  content: " ";
  top: -12px;
  left: 113px;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #ececec;
}
.annotation-cloud .link-add-wrapper {
  display: none;
  margin: 4px 0;
}
.annotation-cloud .link-add-wrapper .url-text-area {
  height: 34px;
  padding: 2px;
  color: #202020;
  background: white;
  font-family: var(--font-family-georgia);
  font-size: 13px;
  line-height: 1.2;
  resize: none;
  outline: none;
  flex: 1 auto;
  margin-right: 4px;
  border: 0 none;
  z-index: 1;
  position: relative;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -khtml-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
}
.annotation-cloud .link-add-wrapper .btn {
  margin: 0;
  font-family: var(--font-family-cantarell);
  font-size: 10px;
  text-transform: uppercase;
  color: #202020;
  border: 1px solid #202020;
  background-color: #fff;
}
.annotation-cloud .link-add-wrapper .btn:hover {
  background-color: #202020;
  color: #fff;
}
.annotation-cloud .link-add-wrapper.show {
  display: flex;
}

.annotation-tooltip {
  -webkit-animation: show-annotation 0.1s forwards;
  -moz-animation: show-annotation 0.1s forwards;
  -o-animation: show-annotation 0.1s forwards;
  animation: show-annotation 0.1s forwards;
  position: absolute;
  background: #ececec;
  color: #202020;
  padding: 5px;
  font-size: 12px;
  width: 240px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  z-index: 2;
}
.annotation-tooltip .edit-text, .annotation-tooltip .text-area {
  display: none;
  height: 60px;
  padding: 3px;
  line-height: 1.2;
  resize: none;
  outline: none;
  width: 100%;
  margin-bottom: 4px;
  border: 0 none;
  z-index: 1;
  position: relative;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -khtml-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
  color: #202020;
  background: white;
  font-family: var(--font-family-georgia);
  font-size: 13px;
}
.annotation-tooltip .edit-text a, .annotation-tooltip .text-area a {
  color: #ad3a3a;
}
.annotation-tooltip:after {
  content: " ";
  top: -10px;
  left: 100px;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ececec;
}
.annotation-tooltip:before {
  content: " ";
  top: -32px;
  left: 76px;
  width: 70px;
  height: 70px;
  position: absolute;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.annotation-tooltip .text {
  display: block;
  margin: 0;
  padding: 0;
  text-align: left;
  z-index: 1;
  font-size: 14px;
  position: relative;
}
.annotation-tooltip .text > * {
  color: #202020 !important;
}
.annotation-tooltip .text a {
  color: #202020;
  text-decoration: underline;
}
.annotation-tooltip .buttons {
  display: flex;
  margin: 2px 0 0;
  position: relative;
}
.annotation-tooltip .buttons .btn {
  flex: 1;
  font-family: var(--font-family-cantarell);
  font-size: 10px;
  text-transform: uppercase;
  background-color: #202020;
  color: #fff;
  margin: 0 2px;
  cursor: pointer;
}
.annotation-tooltip .buttons .btn:hover {
  background-color: #fff;
  color: #202020;
  border: 1px solid #202020;
}
.annotation-tooltip .buttons .btn.save, .annotation-tooltip .buttons .btn.cancel {
  display: none;
}
.annotation-tooltip.edit-mode .text {
  display: none;
}
.annotation-tooltip.edit-mode .edit-text {
  display: block;
}
.annotation-tooltip.edit-mode .buttons .save, .annotation-tooltip.edit-mode .buttons .cancel {
  display: inline-block;
}
.annotation-tooltip.edit-mode .buttons .edit, .annotation-tooltip.edit-mode .buttons .close-an {
  display: none;
}
.annotation-tooltip.left:after {
  left: 20px;
}
.annotation-tooltip.left:before {
  left: -5px;
}
.annotation-tooltip.right:after {
  left: 180px;
}
.annotation-tooltip.right:before {
  left: 155px;
}
.annotation-tooltip .link-add-wrapper {
  display: none;
  justify-content: space-between;
  margin-bottom: 5px;
}
.annotation-tooltip .link-add-wrapper .url-text-area {
  height: 30px;
  padding: 2px;
  font-family: var(--font-family-titillium);
  font-size: 14px;
  line-height: 1.2;
  resize: none;
  outline: none;
  flex: 1 auto;
  margin-right: 4px;
  border: 0 none;
  z-index: 1;
  position: relative;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -khtml-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
  background: white;
  color: #5b5151;
}
.annotation-tooltip .link-add-wrapper .btn {
  margin: 0;
  padding: 0 2px;
}
.annotation-tooltip .link-add-wrapper.show {
  display: flex;
}

@-webkit-keyframes show-annotation {
  0% {
    opacity: 0;
    margin-top: 5px;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    margin-top: 0;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes show-annotation {
  0% {
    opacity: 0;
    margin-top: 5px;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    margin-top: 0;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes show-annotation {
  0% {
    opacity: 0;
    margin-top: 5px;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    margin-top: 0;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes show-annotation {
  0% {
    opacity: 0;
    margin-top: 5px;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    margin-top: 0;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.ann-point {
  display: inline-block;
  position: relative;
  color: #fff;
  bottom: 10px;
  margin: 0 5px;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  background: #202020;
  width: 20px;
  height: 20px;
  line-height: 1.4;
  font-weight: normal;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.ann-point .edit-text {
  display: none;
  height: 60px;
  font-family: var(--font-family-titillium);
  font-size: 14px;
  line-height: 1.2;
  resize: none;
  outline: none;
  width: 100%;
  margin-bottom: 4px;
  border: 0 none;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -khtml-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
}
.ann-point .ann-text {
  -webkit-animation: show-annotation 0.1s forwards;
  -moz-animation: show-annotation 0.1s forwards;
  -o-animation: show-annotation 0.1s forwards;
  animation: show-annotation 0.1s forwards;
  position: absolute;
  top: 36px;
  left: -100px;
  background: #5b5151;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  width: 220px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.ann-point .ann-text:after {
  content: " ";
  top: -10px;
  left: 100px;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #5b5151;
}
.ann-point .ann-text:before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  height: 16px;
  top: -16px;
}
.ann-point .ann-text .text {
  display: block;
  margin: 0;
  padding: 0;
  text-align: left;
  position: relative;
  z-index: 1;
}
.ann-point .ann-text .buttons {
  margin: 2px 0 0;
  text-align: left;
  display: flex;
}
.ann-point .ann-text .buttons .btn {
  display: inline-block;
  margin: 0 2px 0 0;
  padding: 2px 3px;
}
.ann-point .ann-text .buttons .btn.save, .ann-point .ann-text .buttons .btn.cancel {
  display: none;
}
.ann-point .ann-text.edit-mode .text {
  display: none;
}
.ann-point .ann-text.edit-mode textarea {
  display: block;
}
.ann-point .ann-text.edit-mode .buttons .save, .ann-point .ann-text.edit-mode .buttons .cancel {
  display: inline-block;
}
.ann-point .ann-text.edit-mode .buttons .edit {
  display: none;
}
.ann-point .ann-text.left {
  left: -20px;
}
.ann-point .ann-text.left:after {
  left: 20px;
}
.ann-point .ann-text.right {
  left: -180px;
}
.ann-point .ann-text.right:after {
  left: 180px;
}

/* latin */
@font-face {
  font-family: "Cantarell";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Cantarell Regular"), local("Cantarell-Regular"), url("/assets/fonts/Cantarell-Regular-Latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Merriweather Regular"), local("Merriweather-Regular"), url("/assets/fonts/Merriweather-Regular-Latin-Ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Merriweather Regular"), local("Merriweather-Regular"), url("/assets/fonts/Merriweather-Regular-Latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Merriweather Bold"), local("Merriweather-Bold"), url("/assets/fonts/Merriweather-Bold-Latin-Ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Merriweather Bold"), local("Merriweather-Bold"), url("/assets/fonts/Merriweather-Bold-Latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Titillium Web Light"), local("TitilliumWeb-Light"), url("/assets/fonts/TitilliumWeb-Light-Latin-Ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Titillium Web Light"), local("TitilliumWeb-Light"), url("/assets/fonts/TitilliumWeb-Light-Latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Titillium Web Regular"), local("TitilliumWeb-Regular"), url("/assets/fonts/TitilliumWeb-Regular-Latin-Ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Titillium Web Regular"), local("TitilliumWeb-Regular"), url("/assets/fonts/TitilliumWeb-Regular-Latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Titillium Web Bold"), local("TitilliumWeb-Bold"), url("/assets/fonts/TitilliumWeb-Bold-Latin-Ext.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Titillium Web Bold"), local("TitilliumWeb-Bold"), url("/assets/fonts/TitilliumWeb-Bold-Latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

.ql-editor .p-hovered {
  position: relative;
  background-color: rgba(222, 226, 230, 0.3215686275);
}
.ql-editor .content-paragraph {
  clear: both;
  font-style: italic;
  background-color: rgba(250, 250, 250, 0.6117647059) !important;
  border-top: 1px solid rgba(128, 128, 128, 0.2);
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  margin: 7px 0;
  padding: 5px;
}

.main-note-view__content__current__wrapper .content-paragraph {
  clear: both;
  font-style: italic;
  background-color: rgba(250, 250, 250, 0.6117647059) !important;
  border-top: 1px solid rgba(128, 128, 128, 0.2);
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  margin: 7px 0 15px;
  padding: 5px;
}

:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

html, body {
  height: 100%;
}

html {
  font-size: 18px;
  background: #f7f7f7;
}
@media only screen and (min-width : 1460px) {
  html {
    font-size: 20px;
  }
}
html.device--desktop .grid, html.device--mobile .grid {
  margin: 55px auto 30px;
}
html.device--desktop .container, html.device--mobile .container {
  margin-top: 80px;
}

body {
  background: #f7f7f7;
  font-display: swap;
}
body.fixed {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  -webkit-overflow-scrolling: touch;
}

.column--left {
  flex: 0 220px;
  margin: 0 30px 0 0;
}
.column--right {
  flex: 0 390px;
  margin: 56px 0 0 60px;
}
.column--center {
  flex: 1;
}

@media only screen and (max-width : 772px) {
  html, body {
    height: 100%;
    min-height: 100%;
  }
}
.navbar-section .navbar-toggler i {
  color: white;
}

button[disabled] {
  background-color: rgb(167, 167, 167) !important;
  border: 1px solid rgb(167, 167, 167) !important;
}

form button {
  margin-top: 12px;
  margin-bottom: 12px;
}
form label {
  margin-top: 0px;
}
form.notd-format {
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 6px;
}
form.notd-noformat {
  background: transparent;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 0px;
  box-shadow: none;
}

.notd-input-error {
  background-color: #dc4444;
  color: white;
  margin: 5px 0px;
  border-radius: 10px;
  word-break: break-word;
  padding: 4px 10px;
  font-size: 14px;
}

::placeholder {
  color: rgb(150, 150, 150) !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: rgb(150, 150, 150) !important;
}

::-ms-input-placeholder {
  color: rgb(150, 150, 150) !important;
}

.pac-container {
  z-index: 10000 !important;
}

.btnNotd, .btn.notd-cancel-btn, .btn.notd-save-btn, .btn.notd-primary-btn, .btn.notd-secondary-btn {
  border-radius: 6px;
  font-family: var(--font-family-palatino);
  font-size: 13px;
  text-decoration: none solid rgb(255, 255, 255);
  text-align: center;
}

.btn.notd-secondary-btn {
  min-width: 107px;
  height: 32px;
  background-color: #557b83;
  color: #ffffff;
}

.btn.notd-primary-btn {
  background-color: #ad3a3a;
  color: #ffffff;
}

.btn.notd-save-btn {
  background-color: rgb(85, 181, 201);
  color: #ffffff;
}

.btn.notd-cancel-btn {
  background-color: rgb(173, 129, 58);
  color: #ffffff;
}

.img-container img {
  max-width: 100%;
}

.uploader-form .file-upload-group {
  margin-bottom: 0px;
}
.uploader-form .notd-thumb > img {
  width: 73px;
  margin-bottom: 10px;
}
.uploader-form input, .uploader-form textarea, .uploader-form select, .uploader-form label.file-display {
  border-radius: 6px !important;
  background-color: white !important;
  color: black !important;
}
.uploader-form form label, .uploader-form form input {
  margin-top: 0;
  margin-bottom: 0;
}
.uploader-form form label {
  font-size: 13px;
}

.section {
  font-weight: bold;
  margin-top: 20px;
}

select.form-control {
  padding-top: 0px;
  padding-bottom: 0px;
}

.notd-copyright {
  font-weight: bold;
  margin-top: 10px;
}

.notd-filter-element {
  margin-top: 10px;
}

.stream-header-link:hover {
  text-decoration: none;
}

.button-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
@media only screen and (min-width: 768px) {
  .button-wrapper button {
    margin: 0;
  }
}

.notd-invitation .message {
  padding: 10px;
  font-weight: bold;
}
.notd-invitation .message p span {
  color: #ad3a3a;
}
.notd-invitation .buton-wrapper {
  display: flex;
  justify-content: flex-end;
}
.notd-invitation a {
  color: #557b83;
}

.notd-table {
  overflow: auto;
  font-size: 14px;
}
.notd-table .notd-table-header {
  background: rgb(91, 81, 81);
  color: white;
  margin: 0;
}
.notd-table .notd-table-row {
  border-style: none none solid none;
  border-width: 1px;
  border-color: rgb(194, 194, 194);
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0px;
  word-break: break-all;
}
@media only screen and (max-width: 840px) {
  .notd-table .notd-table-header, .notd-table .notd-table-row {
    min-width: 720px;
  }
}

button[disabled] {
  cursor: no-drop;
}

ngb-popover-window.popover {
  color: white;
}
ngb-popover-window.popover .popover-body {
  padding: 5px;
  font-size: 13px;
  font-weight: normal;
  background: #fff;
  border-radius: 5px;
}
ngb-popover-window.popover:after {
  display: none;
}

ngb-pagination {
  margin-top: 5px;
  margin-bottom: 5px;
}
ngb-pagination.boundary-links > ul a[aria-label=Next], ngb-pagination.boundary-links > ul a[aria-label=Previous], ngb-pagination.boundary-links > ul a[aria-label=First], ngb-pagination.boundary-links > ul a[aria-label=Last] {
  border-color: transparent !important;
  background-color: transparent !important;
  font-weight: bold;
}
ngb-pagination .page-link {
  color: #fff;
  background-color: #423230;
  border-color: transparent rgb(230, 230, 230);
}
@media only screen and (max-width: 500px) {
  ngb-pagination .page-link {
    border-color: transparent;
  }
}
ngb-pagination .page-item.active .page-link {
  color: #fff;
  background-color: #423230;
  border-color: transparent;
}
ngb-pagination ul.pagination {
  flex-wrap: wrap;
  justify-content: space-around;
}
ngb-pagination li, ngb-pagination a {
  outline: 0;
}
ngb-pagination .page-link {
  color: #423230;
  background-color: transparent;
  user-select: none;
  border-radius: 2px;
}
ngb-pagination .disabled {
  opacity: 0.5;
}

.fa-spin.big {
  margin-top: 5px;
  margin-bottom: 5px;
}

.modal-content .modal-cross {
  position: absolute;
  top: 11px;
  right: 11px;
  cursor: pointer;
  width: 32px;
  height: 32px;
}
.modal-content .modal-cross svg {
  fill: rgb(85, 123, 131);
  filter: none;
}

.grid {
  width: auto;
  margin: 0 auto;
  /*
  @media only screen and (max-width: 991px) {
    width: auto;
  }
  */
}
@media only screen and (max-width : 736px) {
  .grid {
    width: auto;
  }
}

.notd-text.content {
  color: #423230;
  padding: 20px 40px 100px;
}
@media only screen and (max-width: 991px) {
  .notd-text.content {
    padding: 20px 40px 30px;
  }
}
.notd-text.content .subpage-title {
  font-size: 38px;
  padding: 14px 0;
}
.notd-text.content h3 {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 10px;
}
.notd-text.content .content-link {
  color: #39aea9;
  font-weight: bold;
}
@media only screen and (max-width : 736px) {
  .notd-text.content p {
    line-height: 20px;
  }
}
.notd-text.content .side-nav {
  display: none;
}
@media only screen and (max-width : 736px) {
  .notd-text.content .side-nav {
    display: flex;
  }
}

.navigation-bar {
  margin: 0 0 20px;
}
.navigation-bar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.navigation-bar ul li {
  display: inline-block;
  padding: 0 0 3px 0;
}
.navigation-bar ul li a {
  color: #39aea9;
  font-weight: normal;
  font-family: var(--font-family-georgia);
  text-transform: uppercase;
  font-size: 0.7em;
}
.navigation-bar ul li:after {
  content: ">";
  display: inline-block;
  color: #5b5151;
  padding: 0 3px 0 5px;
}
.navigation-bar ul li:last-child a {
  color: #5b5151;
}
.navigation-bar ul li:last-child:after {
  content: "";
}

.container {
  margin-top: 20px;
  display: flex;
  max-width: inherit;
}
@media only screen and (max-width : 736px) {
  .container {
    flex-direction: column;
    min-height: calc(100% - 160px);
  }
}
.container .content-wrapper {
  flex: 1 1 auto;
}
.container .content-wrapper h3 {
  margin-bottom: 15px;
}
@media only screen and (max-width : 736px) {
  .container .content-wrapper h3 {
    margin-left: 30px;
  }
}
@media only screen and (min-width: 576px) {
  .container {
    width: 92%;
  }
}

.modal-content {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -khtml-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}
.modal-content .modal-header button.close {
  background: none !important;
}
.modal-content .modal-header button.close span {
  position: relative;
  top: -10px;
  right: 10px;
}
*:focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}

.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}

.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}

.info-message {
  display: flex;
  margin: 0 0 10px;
  padding: 2px 10px;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ced4da;
  background: rgba(206, 212, 218, 0.1);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.info-message .ng-fa-icon {
  color: #557b83;
  font-size: 24px;
}
.info-message p {
  margin: 0 6px;
  font-size: 14px;
}
.info-message.large p {
  font-size: 1.2em;
}
.info-message.alert {
  border: 1px solid rgba(140, 34, 34, 0.1607843137);
  background: rgba(173, 58, 58, 0.1607843137);
}
.info-message.alert .ng-fa-icon {
  color: #dc3545;
}

.table-basic-style {
  padding: 0 0 20px;
}
@media only screen and (max-width : 736px) {
  .table-basic-style {
    overflow: auto;
  }
}
.table-basic-style table {
  width: 100%;
  border: 1px solid #d6d6d6;
  background-color: #fff;
  margin-bottom: 10px;
  overflow: hidden;
  -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  -moz-box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 20px 0;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -khtml-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
@media only screen and (max-width : 736px) {
  .table-basic-style table {
    width: 800px;
  }
}
.table-basic-style table .link {
  color: #557b83;
}
.table-basic-style table thead th {
  background: rgba(214, 214, 214, 0.4784313725);
  padding: 3px;
  text-align: center;
}
.table-basic-style table tbody tr {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  background: none;
}
.table-basic-style table tbody tr td {
  text-align: center;
  font-size: 0.8rem;
  padding: 3px 1px;
  border-bottom: 1px solid rgba(214, 214, 214, 0.7294117647);
}
.table-basic-style table tbody tr td.note-name {
  max-width: 180px;
}
.table-basic-style table tbody tr td.location {
  max-width: 140px;
}
.table-basic-style table tbody tr td.options {
  min-width: 80px;
}
.table-basic-style table tbody tr:hover, .table-basic-style table tbody tr.selected {
  background: rgba(245, 245, 245, 0.8509803922);
}
.table-basic-style table tbody tr .btn {
  padding: 2px 3px;
  margin: 1px 2px;
  font-size: 12px;
}
.table-basic-style table tbody tr .btn:hover {
  background: #557b83;
}
.table-basic-style table tbody tr .tag {
  background: #557b83;
  color: #fff;
  font-size: 11px;
  padding: 2px 5px;
  margin: 1px;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -khtml-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
.table-basic-style .show-more .btn {
  font-size: 16px;
  color: white;
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -ms-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}
.table-basic-style .show-more .btn:hover {
  background: #557b83;
}

.clear {
  clear: both;
}

.button-spinner {
  position: relative;
  padding-right: 35px;
  pointer-events: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.button-spinner:after {
  content: "";
  position: absolute;
  right: 10px;
  border: 3px solid #fff;
  border-color: #fff #fff transparent transparent;
  width: 20px;
  height: 20px;
  -webkit-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -moz-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -o-animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.audio-file-included {
  font-size: 0.7em;
  background: rgba(235, 235, 235, 0.3098039216);
  border: 1px solid #d6d6d6;
  padding: 3px 7px 3px 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  display: inline-block;
}
.audio-file-included svg {
  margin-right: 3px;
  color: #557b83;
}

.preview-modal .modal-content {
  padding: 35px 25px 25px;
  position: relative;
}
.preview-modal .modal-content .preview-note-title {
  font-family: var(--font-family-palatino);
  color: black;
  font-size: 24px;
  margin: 7px 0 12px;
  font-weight: 700;
  word-break: break-word;
}
.preview-modal .modal-content .content-paragraph {
  clear: both;
  font-style: italic;
  background-color: rgba(250, 250, 250, 0.6117647059) !important;
  border-top: 1px solid rgba(128, 128, 128, 0.2);
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  margin: 7px 0;
  padding: 5px;
}
.preview-modal .modal-content blockquote {
  border-left: 6px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
  line-height: 1.4em;
  font-size: 0.9em;
}
.preview-modal .modal-content .tags-section {
  margin-bottom: 10px;
}
.preview-modal .modal-content .tags-section .tag {
  color: #f5f5f5;
  font-size: 13px;
  background: #39aea9;
  display: inline-block;
  padding: 1px 7px;
  margin-right: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  user-select: none;
}
.preview-modal .modal-content .premium-content {
  font-size: 1em;
  font-family: var(--font-family-palatino);
  line-height: 1.9em;
  flex: 1 0;
  word-break: break-word;
}
.preview-modal .modal-content .premium-content img {
  min-width: 300px;
  max-width: 100%;
}
.preview-modal .modal-content .premium-content img + em {
  display: block;
}
@media only screen and (max-width : 736px) {
  .preview-modal .modal-content .premium-content {
    width: 100%;
  }
  .preview-modal .modal-content .premium-content .caption-image-wrapper {
    display: block !important;
    align-items: unset !important;
    flex-direction: unset !important;
    flex-flow: unset !important;
  }
  .preview-modal .modal-content .premium-content .caption-image-wrapper img, .preview-modal .modal-content .premium-content .caption-image-wrapper span {
    width: 100% !important;
  }
  .preview-modal .modal-content .premium-content .caption-image-wrapper span {
    padding: 2px 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1803921569) !important;
  }
  .preview-modal .modal-content .premium-content .caption-image-wrapper.left, .preview-modal .modal-content .premium-content .caption-image-wrapper.right {
    width: 40% !important;
  }
  .preview-modal .modal-content .premium-content img {
    min-width: initial !important;
  }
}
@media only screen and (max-width : 1024px) {
  .preview-modal .modal-content .premium-content .caption-image-wrapper {
    min-width: 49%;
  }
  .preview-modal .modal-content .premium-content .caption-image-wrapper img {
    min-width: initial;
  }
}
.preview-modal .modal-content .premium-content ol {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  counter-reset: li-counter;
}
.preview-modal .modal-content .premium-content ol li {
  padding: 0 0 7px;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-num;
}
.preview-modal .modal-content .premium-content ol li:before {
  content: counter(list-num, decimal) ". ";
  counter-increment: li-counter;
}
.preview-modal .modal-content .premium-content ol li.ql-indent-1 {
  padding-left: 20px;
  counter-increment: list-1;
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.preview-modal .modal-content .premium-content ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}
.preview-modal .modal-content .premium-content ol li.ql-indent-2 {
  padding-left: 40px;
  counter-increment: list-2;
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.preview-modal .modal-content .premium-content ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}
.preview-modal .modal-content .premium-content ol li.ql-indent-3 {
  padding-left: 60px;
  counter-increment: list-3;
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.preview-modal .modal-content .premium-content ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}
.preview-modal .modal-content .premium-content ol li.ql-indent-4 {
  padding-left: 80px;
  counter-increment: list-4;
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.preview-modal .modal-content .premium-content ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}
.preview-modal .modal-content .premium-content ol li.ql-indent-5 {
  padding-left: 100px;
  counter-increment: list-5;
  counter-reset: list-6 list-7 list-8 list-9;
}
.preview-modal .modal-content .premium-content ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}
.preview-modal .modal-content .premium-content ol li.ql-indent-6 {
  padding-left: 120px;
  counter-increment: list-6;
  counter-reset: list-7 list-8 list-9;
}
.preview-modal .modal-content .premium-content ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}
.preview-modal .modal-content .premium-content ol li.ql-indent-7 {
  padding-left: 140px;
  counter-increment: list-7;
  counter-reset: list-8 list-9;
}
.preview-modal .modal-content .premium-content ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}
.preview-modal .modal-content .premium-content ol li.ql-indent-8 {
  padding-left: 160px;
}
.preview-modal .modal-content .premium-content ol li.ql-indent-8 .ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
}
.preview-modal .modal-content .premium-content ol li.ql-indent-8 .ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}
.preview-modal .modal-content .premium-content ol li.ql-indent-8 .ql-editor ol li.ql-indent-8 {
  counter-reset: list-9;
}
.preview-modal .modal-content .premium-content ul {
  list-style: none;
  padding: 0;
}
.preview-modal .modal-content .premium-content ul li {
  padding: 0 0 7px;
}
.preview-modal .modal-content .premium-content ul li:before {
  content: "•";
  padding: 0 4px;
}
.preview-modal .modal-content .premium-content .ql-size-small {
  font-size: 0.75em;
}
.preview-modal .modal-content .premium-content .ql-size-large {
  font-size: 1.5em;
}
.preview-modal .modal-content .premium-content .ql-size-huge {
  font-size: 2.5em;
}
.preview-modal .modal-content .premium-content .ql-align-justify {
  text-align: justify;
}
.preview-modal .modal-content .premium-content .ql-align-center {
  text-align: center;
}
.preview-modal .modal-content .premium-content .ql-align-left {
  text-align: left;
}
.preview-modal .modal-content .premium-content .ql-align-right {
  text-align: right;
}
.preview-modal .modal-content .close-preview-modal-btn {
  display: inline-block;
  cursor: pointer;
  top: 4px;
  width: 30px;
  height: 30px;
  right: 5px;
  position: absolute;
  z-index: 1;
}
.preview-modal .modal-content .close-preview-modal-btn svg {
  width: 30px;
  height: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.preview-modal .modal-content .close-preview-modal-btn svg:hover {
  opacity: 0.7;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.preview-modal .modal-content .close-button {
  background-color: #39aea9;
  color: white;
}
.preview-modal .modal-content .close-button:hover {
  background-color: #557b83;
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}

@keyframes button-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
:root {
  --font-family-palatino: Palatino, Georgia, "Times New Roman", Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: "Titillium Web", sans-serif;
  --font-family-cantarell: "Cantarell", sans-serif;
  --font-family-roboto: "Roboto", sans-serif;
  --color-green: #39aea9;
}

.notd__grid {
  display: flex;
  flex: 1 0 auto;
}
.notd__grid.wrapper--centered {
  margin: 0 auto;
  width: 1140px;
}
@media only screen and (max-width : 1024px) {
  .notd__grid.wrapper--centered {
    width: auto;
  }
}
@media only screen and (max-width : 736px) {
  .notd__grid.wrapper--centered {
    width: auto;
  }
}
.notd__grid.wrapper--organized {
  margin: 85px auto 30px !important;
}
@media only screen and (max-width : 1024px) {
  .notd__grid.wrapper--organized {
    margin: 75px 15px 10px !important;
  }
}
@media only screen and (max-width : 736px) {
  .notd__grid.wrapper--organized {
    margin: 116px 15px 30px !important;
  }
}
.notd__full-vh {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}
.notd__headline {
  font-family: var(--font-family-palatino);
}
.notd__headline--h2 {
  font-size: 28px;
  font-weight: normal;
}
.notd__button {
  padding: 4px 10px;
  color: #fff;
  text-align: center;
  font-size: 0.9em;
  display: inline-block;
  border: 0 none;
  margin: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  outline: 0;
}
.notd__button--v2 {
  background-color: transparent;
  font-family: var(--font-family-cantarell);
  text-transform: uppercase;
  color: #757575;
  font-size: 0.5em;
  border: 0 none;
  padding: 7px 8px;
  cursor: pointer;
  border: 1px solid #757575;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -khtml-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}
.notd__button--v2:hover {
  color: white;
  border: 1px solid #202020;
  background-color: #202020;
}
.notd__button--v2.bg--transparent {
  background-color: transparent;
}
.notd__button--v2.border--green {
  border: 1px solid #39aea9;
  color: #39aea9;
}
.notd__button--v2.border--green:hover {
  border: 1px solid #39aea9;
  background-color: #39aea9;
  color: white;
}
.notd__button--v2.button--black {
  color: white;
  border: 1px solid #202020;
  background-color: #202020;
}
.notd__button--v2.button--black:hover {
  color: #757575;
  background-color: transparent;
}
.notd__button--v2.button--green {
  color: white;
  border: 1px solid #39aea9;
  background-color: #39aea9;
}
.notd__button--v2.button--green:hover {
  border: 1px solid #202020;
  background-color: #202020;
}
.notd__button--v2.button--white {
  color: black;
  background-color: white;
  border: 1px solid rgba(214, 214, 214, 0.31);
}
.notd__button--v2.button--white:hover {
  background-color: rgba(228, 228, 228, 0.73);
}
.notd__button--v2.text-transform-lowercase {
  text-transform: lowercase;
}
.notd__button--v2.text-transform-capitalize {
  text-transform: capitalize;
}
.notd__button:focus {
  outline: none;
}
.notd__button.large {
  font-size: 1.2em;
  padding: 6px 8px;
}
.notd__button.large-2x {
  font-size: 1.2em;
  padding: 10px 12px;
}
.notd__button:disabled {
  background-color: rgba(228, 228, 228, 0.73) !important;
}
.notd__button.button-black {
  background-color: black;
}
.notd__button.button-black:hover {
  background-color: rgba(115, 115, 115, 0.73);
}
.notd__button.button--white {
  color: black;
  background-color: white;
  border: 1px solid rgba(214, 214, 214, 0.7294117647);
}
.notd__button.button--white:hover {
  background-color: rgba(228, 228, 228, 0.73);
}
.notd__button.button--blue {
  background-color: #39aea9;
}
.notd__button.button--blue:hover {
  background-color: rgba(192, 192, 192, 0.73);
}
.notd__button.button--red {
  background-color: #ad3a3a;
}
.notd__button.button--red:hover {
  background-color: rgba(192, 192, 192, 0.73);
}
.notd__button.button--black {
  color: white;
  background-color: black;
}
.notd__button.button--black:hover {
  color: black;
  background-color: rgba(228, 228, 228, 0.73);
}
.notd__button.loading:before {
  margin: 0 7px;
  width: 18px;
  height: 18px;
  content: "";
  background-size: cover;
  animation: rotating 1.2s infinite linear;
}
.notd__button.width--md {
  min-width: 130px;
}
.notd__table {
  width: 100%;
  border: 0;
}
.notd__table thead tr {
  border-bottom: 1px solid rgba(214, 214, 214, 0.7294117647) !important;
}
.notd__table thead tr:hover {
  background: transparent !important;
}
.notd__table thead tr th {
  font-size: 1.1em;
  padding: 8px 0;
  text-align: center;
  vertical-align: middle;
}
.notd__table tr {
  border-bottom: 1px solid rgba(214, 214, 214, 0.7294117647);
}
@media only screen and (max-width : 736px) {
  .notd__table tr {
    display: grid;
  }
}
.notd__table tr:last-child {
  border-bottom: 0 none;
}
.notd__table tr td {
  vertical-align: middle;
  text-align: center;
  padding: 10px;
}
.notd__table.tr-hovered tr {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}
.notd__table.tr-hovered tr:hover {
  background-color: rgba(211, 211, 211, 0.1215686275);
}
.notd__table.tr-pointer {
  cursor: pointer;
}
.notd__table__actions {
  text-align: right;
}
.notd__table__actions button {
  padding: 4px 10px;
  color: #fff;
  text-align: center;
  display: inline-block;
  border: 0 none;
  margin: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}
.notd__table__actions button:disabled {
  background-color: rgba(228, 228, 228, 0.73) !important;
}
.notd__table__actions button.button--black {
  background-color: black;
}
.notd__table__actions button.button--black:hover {
  background-color: rgba(115, 115, 115, 0.73);
}
.notd__table__actions button.button--white {
  color: black;
  border: 1px solid rgba(214, 214, 214, 0.7294117647);
}
.notd__table__actions button.button--white:hover {
  background-color: rgba(228, 228, 228, 0.73);
}
.notd__table__actions button.button--blue {
  background-color: #17a5ae;
}
.notd__table__actions button.button--blue:hover {
  background-color: rgba(192, 192, 192, 0.73);
}
.notd__table__actions button.button--red {
  background-color: red;
}
.notd__image-wrapper {
  position: relative;
  overflow: hidden;
}
.notd__image-wrapper img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.notd__image-wrapper.circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -khtml-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
.notd__image-wrapper.image-40-40 {
  width: 40px;
  height: 40px;
}
.notd__image-wrapper.image-60-60 {
  width: 60px;
  height: 60px;
}
.notd__image-wrapper.image-80-80 {
  width: 80px;
  height: 80px;
}
.notd__image-wrapper.image-120-120 {
  width: 120px;
  height: 120px;
}
.notd__label {
  padding: 4px 10px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -khtml-border-radius: 14px;
  -o-border-radius: 14px;
  border-radius: 14px;
  color: #fff;
  text-align: center;
  display: inline-block;
}
.notd__label.label--active, .notd__label.label--red {
  background-color: red;
}
.notd__label.label--blue {
  background-color: #17a5ae;
}
.notd__label.label--white {
  background-color: #fff;
  color: #000;
}
.notd__label.label--black {
  background-color: black;
}
.notd__label.label--disabled {
  background-color: lightgrey;
}
.notd__label.large {
  font-size: 1.2em;
}
.notd__input {
  font-family: var(--font-family-titillium);
  line-height: 2em;
  padding: 5px;
  border: 1px solid rgba(214, 214, 214, 0.7294117647);
  min-width: 120px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.notd__input.input--error {
  border: 1px solid red;
}
.notd__textarea {
  font-family: var(--font-family-titillium);
  line-height: 2em;
  padding: 5px;
  border: 1px solid rgba(214, 214, 214, 0.7294117647);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  min-height: 140px;
}
.notd__textarea.input--error {
  border: 1px solid indianred;
}
.notd__select {
  font-family: var(--font-family-titillium);
  line-height: 2em;
  padding: 5px;
  border: 1px solid rgba(214, 214, 214, 0.7294117647);
  background: transparent;
  height: 40px;
  min-width: 120px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.notd__select.input--error {
  border: 1px solid indianred;
}
.notd__form {
  display: flex;
  flex-wrap: wrap;
}
.notd__form .element-holder {
  padding: 5px 0;
  display: flex;
  flex: 0 100%;
  flex-direction: column;
}
.notd__form .element-holder label {
  font-weight: normal;
}
.notd__form .element-holder input.half-size {
  width: 50%;
}
.notd__form .element-holder .form-error {
  display: block;
  color: indianred;
  font-size: 1em;
  padding: 2px 0;
}
.notd__modal .modal-header {
  display: flex;
  align-items: flex-end;
  background-color: rgba(211, 211, 211, 0.1215686275);
}
.notd__modal .modal-header h3 {
  margin: 0 10px 0 0;
}
.notd__modal .modal-footer {
  background-color: rgba(211, 211, 211, 0.1215686275);
}
.notd__panel-box {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 10px 0;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.notd__panel-box .header, .notd__panel-box .footer {
  color: #333;
  padding: 10px;
  background-color: #f5f5f5;
}
.notd__panel-box .header {
  border-bottom: 1px solid #ddd;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.notd__panel-box .header h3 {
  font-size: 1.25em;
  color: #333;
  margin: 0;
}
.notd__panel-box .body {
  padding: 10px;
}
.notd__panel-box .footer {
  border-top: 1px solid #ddd;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.notd__subpage-title {
  font-size: 1.8em;
  color: #4a4a4a;
  margin: 0;
  padding: 0;
}
.notd__m-0 {
  margin: 0 !important;
}
.notd__m-0-auto {
  margin: 0 auto !important;
}
.notd__m-t-0 {
  margin-top: 0 !important;
}
.notd__m-t-10 {
  margin-top: 10px !important;
}
.notd__m-t-15 {
  margin-top: 15px !important;
}
.notd__m-t-20 {
  margin-top: 20px !important;
}
.notd__m-t-40 {
  margin-top: 40px !important;
}
.notd__m-b-0 {
  margin-bottom: 0 !important;
}
.notd__m-b-5 {
  margin-bottom: 5px !important;
}
.notd__m-b-10 {
  margin-bottom: 10px !important;
}
.notd__m-b-15 {
  margin-bottom: 15px !important;
}
.notd__m-b-20 {
  margin-bottom: 20px !important;
}
.notd__m-l-0 {
  margin-left: 0 !important;
}
.notd__m-l-10 {
  margin-left: 10px !important;
}
.notd__m-r-5 {
  margin-right: 5px !important;
}
.notd__m-r-10 {
  margin-right: 10px !important;
}
.notd__m-r-20 {
  margin-right: 20px !important;
}
.notd__p-10 {
  padding: 10px !important;
}
.notd__p-l-0 {
  padding-left: 0 !important;
}
.notd__p-l-10 {
  padding-left: 10px !important;
}
.notd__p-r-10 {
  padding-right: 0 !important;
}
.notd__p-r-10 {
  padding-right: 10px !important;
}
.notd__width-100p {
  width: 100%;
}
.notd__link {
  color: #39aea9;
  text-decoration: none;
}
.notd__link:hover {
  color: grey;
}
.notd__text--blue {
  color: #39aea9;
}
.notd__text--strong {
  font-weight: bold;
}
.notd__text--centered {
  text-align: center !important;
}
.notd__datepicker {
  font-weight: normal;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 8px;
  margin-top: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.notd__datepicker .bs-datepicker-head {
  text-align: center;
}
.notd__datepicker .bs-datepicker-head button {
  background-color: #39aea9;
  color: #fff;
  padding: 3px 6px;
  display: inline-block;
  border: 0 none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -khtml-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.notd__datepicker .bs-datepicker-head button.previous, .notd__datepicker .bs-datepicker-head button.next {
  background-color: black;
  padding: 3px 8px;
}
.notd__datepicker .bs-datepicker-head button:hover {
  background-color: grey;
}
.notd__display--flex {
  display: flex;
}
.notd__display--inline-flex {
  display: inline-flex;
}

@-webkit-keyframes rotating {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  100% {
    transform: rotate(360deg);
  }
}