@import '../../scss/variables.scss';

:host {
  overflow: auto;

  table {
    margin: 10px 0;
    min-width: 800px;
    
    thead {
      background-color: #80808012;

      tr {
        @include max-phone-width() {
          display: table-row;
        }

        th {
          text-align: center;
          padding: 14px 5px;

          &.row-costs {
            width: 200px;
          }

          &.row-extend {
            width: 150px;
          }

          &.label {
            font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
            color: #5d6277;
            font-size: 0.9em;
            font-weight: 500;
            margin: 0 15px 0 0;
            cursor: pointer;
          }
        }
      }
    }

    tbody {
      tr {
        @include max-phone-width() {
          display: table-row;
        }

        td {
          .stream-link {
            &:hover {
              color: black;
              text-decoration: none;
            }
          }

          .button--disabled {
            border: none;
            font-size: .75em;
          }
        }
      }
    }
  }

  .no-subscriptions-results {
    background: #fff;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include radius(15px);

    fa-icon {
      font-size: 2em;
      margin: 0 15px;
      color: #3aada8;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }
}

subscriptions-table {
  display: grid;

  .expired{
    color: red;
  }

  a{
    color: #557b83;
  }

  .price-col{
    >span:nth-child(2) {
      color: red;
      padding-left: 3px;
    }
  }
  .button-col{
    padding-left: 0;
    padding-right: 0;
  }
}

.notd-table {
  .notd-table-header {
    padding: 0 5px;
  }
  .notd-table-row {
    padding: 5px;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto;

  &__spinner {
    position: relative;
    background: #fff;
    @include radius(10px);
    width: 50px;
    height: 50px;

    &:after {
      position: absolute;
      content: '';
      border: 2px solid #444444;
      border-color: #44444478 #44444478 transparent transparent;
      width: 25px;
      height: 25px;
      top: 25%;
      left: 25%;
      @include animation(button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite);
      @include radius(50%);
      @include transform(translate(-25%, -25%));
    }
  }

  &__label {
    font-family: $cantarellFont;
    text-transform: uppercase;
    font-size: 0.7em;
    margin: 10px 0 0;
  }
}



::ng-deep .subscribe-to-stream-modal {
  font-family: var(--font-family-georgia);

  .modal-dialog {
      .modal-content {
          position: relative;
          padding: 20px;

          @include box-shadow(rgb(0 0 0 / 15%) 0px 10px 20px 0px !important);
          @include max-phone-width() {
              padding: 10px;
          }
      }
  }

  &__close-btn {
      display: inline-flex;
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;

      svg {
          height: 26px;
          width: 26px;

          &:hover {
              opacity: .6;
          }
      }
  }

  &__title {
      font-size: 1.4em;
      text-align: center;
      margin: 0;
      padding: 0;

      @include max-phone-width() {
          padding: 0 45px 0 0;
      }
  }

  &__content {
    &__progress {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px 20px;
      margin: 40px 0;
      width: 100%;
      @include radius(10px);

      &__content {
        display: flex;
        align-items: center;
        justify-content: center;

        &__spinner {
          position: relative;
          background: #f4f4f4;
          @include radius(10px);
          width: 50px;
          height: 50px;
          margin: 0 15px 0 0;
  
          &:after {
            position: absolute;
            content: '';
            border: 2px solid #444444;
            border-color: #44444478 #44444478 transparent transparent;
            width: 25px;
            height: 25px;
            top: 25%;
            left: 25%;
            @include animation(button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite);
            @include radius(50%);
            @include transform(translate(-25%, -25%));
          }
        }
  
        &__label {
          font-family: var(--font-family-cantarell);
          text-transform: uppercase;
          font-size: 0.75em;
          margin: 0;
        }
      }

      &.error {
        display: flex;

        svg {
          width: 60px;
          height: 60px;

          margin: 0 20px 0 0;
        }
      }
    }

    &__text {
      margin: 20px 0 10px;
    }

    &__info {
      margin: 10px 0;
      display: flex;
      align-items: center;

      @include max-phone-width() {
          margin: 10px 5px;
      }

      svg {
          width: 26px;
          height: 26px;
          margin: 0 5px 0 0;
      }

      p {
          flex: 1;
          font-size: .8em;
          color: grey;
          margin: 0;
      }
    }

    &__buttons {
      display: flex;
      justify-content: center;
      z-index: 1;

      button {
        margin: 0 2px;
        font-size: .8em;

        &:disabled {
          border: none;
        }
      }
    }

    &__list {
      list-style: none;
      margin: 20px 0 30px;
      padding: 0;

      &__counter {
        margin: 0 5px 0 0;
      }

      &__user {
        flex: 1;
        text-decoration: none;
        color: #39aea9;

        &:hover {
          color: black;
          text-decoration: none;
        }
      }

      &__actions {
        margin: 0 0 0 10px;

        button {
          font-size: .7em;
        }
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px 0;

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &__message {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 30px 10px 10px;
  
      svg {
        width: 250px;
        height: 250px;
      }
  
      p {
        padding: 20px 0;
      }
    }
  }
}